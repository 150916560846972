import OtpComp from 'components/OtpComp'
import React from 'react'
import { Helmet } from 'react-helmet'

const Otp = () => {
  return (
    <>
    <Helmet>
        <title>OTP</title>
      </Helmet>
    <OtpComp />
    </>
  )
}

export default Otp