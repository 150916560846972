import React, { useState, useEffect, useRef } from 'react';

export const CustomDropdownOne = ({ data, value, setValue}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
// close the dropdown  when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <div className="custom-dd-one" onClick={toggle} ref={dropdownRef}>
      <div className="custom-dd-one-wrap">
        <ul>
          <li style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: data.icon }}></li>
          <li className="user-select-none">{value.lable}</li>
          <li>
            <svg width="20" height="20" className='custom-arrow' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 7.5L10 12.5L15 7.5" stroke="#232339" strokeWidth="2" />
            </svg>
          </li>
        </ul>
      </div>
      {show && (
        <div className="options">
          <ul>
            {data.fieldData.map((item, i) => (
              <li key={i} onClick={() => setValue(item)}>
                {item.lable}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
