import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import apis from "services";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryOptions } from "./common/countries";

const CommunityForm = ({ setShowModal }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    country: "",
    gender: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
// SUbmit community form
  const onSubmitCommunity = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apis?.addCommunityMember(data);
      if (response) {
        setShowModal(true);
      }
      // toast.success(response?.data?.message);
      setData({
        name: "",
        email: "",
        phone: "",
        age: "",
        country: "",
        gender: "",
      });
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      if (error?.message) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <div className="container shadow pt-3 pb-5 rounded-3  my-5">
      {/* <p className="text-center gradient__text m-0 h6 mb-4">We Will Contact You Shortly!</p> */}
      <form onSubmit={onSubmitCommunity}>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 my-3">
            <label className="ms-1 fs-6">Name</label>
            <input
              type="text"
              name="name"
              required
              autoComplete="off"
              autoFocus="true"
              onChange={onChange}
              value={data?.name}
              className="contact-form-input"
            />
          </div>
          <div className="col-lg-4 col-md-6 my-3">
            <label className="ms-1 fs-6">Email</label>
            <input
              type="email"
              name="email"
              required
              autoComplete="off"
              autoFocus="true"
              value={data?.email}
              onChange={onChange}
              className="contact-form-input"
            />
          </div>

          <div className="col-lg-4 col-md-6 my-3">
            <label className="ms-1 fs-6">Age</label>
            <input
              type="number"
              name="age"
              required
              autoComplete="off"
              autoFocus="true"
              value={data?.age}
              onChange={onChange}
              className="contact-form-input"
            />
          </div>
          <div className="col-lg-4 col-md-6 my-3">
             <label className="ms-1 fs-6">Phone</label>
           {/* <input
              type="number"
              required
              name="phone"
              value={data?.phone}
              autoComplete="off"
              autoFocus="true"
              onChange={onChange}
              className="contact-form-input"
            /> */}
             <PhoneInput
                  value={data?.phone}
                  country={"se"}
                  className="contact-form-input communityPhone w-100 px-0"
                  onChange={(phone) => setData({ ...data, phone })}
                />
                {/* {error?.phone && (
                  <p className="text-danger m-0 mt-1">{error?.phone}</p>
                )} */}
          </div>

          <div className="col-lg-4 col-md-6 my-3">
            <label className="ms-1 fs-6">Gender</label>
            <select
              name="gender"
              onChange={onChange}
              required
              value={data?.gender}
              className="contact-form-input"
            >
              <option selected disabled className="bg-gold">
                Select gender
              </option>
              <option value="male" className="bg-gold">
                Male
              </option>
              <option value="female" className="bg-gold">
                Female
              </option>
              <option value="other" className="bg-gold">
                Other
              </option>
            </select>
          </div>
          <div className="col-lg-4 col-md-6 my-3">
            <label className="ms-1 fs-6">Country</label>
            <select
              name="country"
              onChange={onChange}
              required
              value={data?.country}
              className="contact-form-input"
            >
              <option selected disabled className="bg-gold">
                Select country
              </option>
              {countryOptions?.map((country, i) => (
                <option key={i} value={country?.value} className="bg-gold">
                  {country?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6 col-lg-4">
            <button
              type="submit"
              className=" border-0 rounded-3 bg-gold text-white w-100 mt-3 py-2 fw-bold"
            >
              {loading ? <Spinner size="sm" /> : "Join"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommunityForm;
