import Joi from "joi";

const validateVerification = (data) => {
  const schema = Joi.object({
    fullName: Joi.string().required().messages({
      "string.base": "Full Name is required*",
      "string.empty": "Full Name is required*",
      "any.required": "Full Name is required*",
    }),
    departureCity: Joi.string().required().messages({
      "string.base": "Departure city is required*",
      "string.empty": "Departure city is required*",
      "any.required": "Departure city is required*",
    }),
    arrivalCity: Joi.string().required().messages({
      "string.base": "Arrival city is required*",
      "string.empty": "Arrival city is required*",
      "any.required": "Arrival city is required*",
    }),
    departureDate: Joi.string().required().messages({
      "string.base": "Departure date is required*",
      "string.empty": "Departure date is required*",
      "any.required": "Departure date is required*",
    }),
    phone: Joi.number().required().messages({
      "number.base": "Phone is required*",
      "number.empty": "Phone is required*",
      "any.required": "Phone is required*",
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .messages({
        "string.base": "Email is required*",
        "string.empty": "Email is required*",
        "any.required": "Email is required*",
      }),
    message: Joi.string().required().messages({
      "string.base": "Message is required*",
      "string.empty": "Message is required*",
      "any.required": "Message is required*",
    }),
  });
  return schema.validate(data);
};
const  validateCancellation = (data) => {
  const schema = Joi.object({
    bookingNumber: Joi.string().required().messages({
      "string.base": "Booking Number is required*",
      "string.empty": "Booking Number is required*",
      "any.required": "Booking Number is required*",
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .messages({
        "string.base": "Email is required*",
        "string.empty": "Email is required*",
        "any.required": "Email is required*",
      }),
    message: Joi.string().required().messages({
      "string.base": "Message is required*",
      "string.empty": "Message is required*",
      "any.required": "Message is required*",
    }),
  });
  return schema.validate(data);
};

export { validateCancellation,validateVerification };
