import React from "react";

const PolicySweedish = () => {
  return (
    <>
      <h2>Integritetspolicy</h2>
      <p>
        Detta är en redogörelse för behandling av personuppgifter i enlighet med
        EU:s allmänna dataskyddsförordning (2016/679) (GDPR)
      </p>
      <p>
        Denna integritetspolicy ("Policy") beskriver hur [Bolaget]
        ("Personuppgiftsansvarig") samlar in och behandlar den registrerades
        personuppgifter i samband med att den registrerade [ex "registrerar sig
        för och tar i bruk [Bolagets] Tjänst"].
      </p>
      <p>I nedan Policy återfinns information om bland annat följande;</p>
      <ul>
        <li>Personuppgiftsansvarig</li>
        <li>Grund och ändamål för behandlingen av personuppgifter.</li>
        <li>Den registrerades rättigheter</li>
      </ul>
      <h4>Personuppgiftsansvarig</h4>
      <ul className="list-unstyled">
        <li>Ultra tech sweden AB</li>
        <li>Organisationsnummer: 5594418765</li>
      </ul>
      <h4>Kontaktuppgifter gällande dataskyddsfrågor</h4>
      <p>
        I alla frågor som rör behandling av personuppgifter och i situationer
        som gäller utövandet av egna rättigheter ska den registrerade kontakta
        den personuppgiftsansvarige skriftligen genom att skicka ett
        e-postmeddelande till info@Flyproview.com
      </p>
      <h4>Registrets namn</h4>
      <p>Personuppgifterna samlas i www.FlyProView.com</p>
      <h4>Grund och ändamål för behandlingen av personuppgifter:</h4>
      <p>Den rättsliga grunden för behandling av personuppgifter är</p>
      <ul>
        <li>Den registrerades samtycke till behandling av personuppgifte</li>
        <li>
          Avtalsförhållandet mellan den registrerade och den
          personuppgiftsansvarige
        </li>
      </ul>
      <p>
        Ändamålen med behandlingen av personuppgifter är att möjliggöra bokning
        av flygbiljetter och hotellrum samt utföra nödvändiga åtgärder för att
        websidan ska kunna fullgöra sin funktion.
      </p>

      <h4>Personuppgifter som behandlas</h4>
      <p>
        Den personuppgiftsanasvarige samlar endast in de personuppgifter från
        den registrerade som är relevanta och nödvändiga för de ändamål som
        beskrivs i denna integritetspolicy.
      </p>
      <p className="fst-italic">
        Följande uppgifter behandlas om registrerade:
      </p>
      <ul className="fst-italic">
        <li>Namn</li>
        <li>Age</li>
        <li>Phone number</li>
        <li>Email</li>
        <li>Location</li>
        <li>Adress</li>
        <li>Pass utfärdandedatum</li>
        <li>Pass utgångsdatum</li>
      </ul>
      <h4>Uppgifternas lagringstid</h4>
      <p>
        Den personuppgiftsansvarige behandlar personuppgifterna i 18 månader.
        Efter denna period raderar eller anonymiserar den
        personuppgiftsansvarige uppgifterna inom 1 månad i enlighet med sina
        raderingsprocesser. Den personuppgiftsansvarige kan vara skyldig att
        behandla vissa av de personuppgifter som finns i registret under en
        längre tid än vad som anges ovan för att uppfylla lagstiftning eller
        myndighetskrav.
      </p>
      <h4>Den registrerades rättigheter</h4>
      <p className="fst-italic fw-semibold">
        Rätt att få tillgång till personuppgifter
      </p>
      <p className="fst-italic">
        Den registrerade har rätt att få en bekräftelse på om dennes
        personuppgifter behandlas och, om de behandlas, rätt att få en kopia av
        sina personuppgifter.
      </p>
      <p className="fst-italic fw-semibold">Rätt att rätta uppgifter</p>
      <p className="fst-italic">
        Den registrerade har rätt att begära att ofullständiga och felaktiga
        personuppgifter som rör denne rättas. Den registrerade har också rätt
        att komplettera ofullständiga personuppgifter genom att skicka in de
        nödvändiga tilläggsuppgifterna.
      </p>
      <p className="fst-italic fw-semibold">Rätt att radera uppgifter</p>
      <p className="fst-italic">
        Den registrerade har rätt att begära radering av sina personuppgifter.
        Detta gäller bland annat när personuppgifterna inte längre behövs för de
        ändamål för vilka de samlats in, eller när personuppgifterna har
        behandlats på ett olagligt sätt, eller om den registrerade återkallat
        det samtycke som behandlingen grundas på.
      </p>
      <p className="fst-italic fw-semibold">
        Rätt till begränsning av behandling
      </p>
      <p className="fst-italic">
        Den registrerade har rätt att begära begränsning av behandlingen av sina
        personuppgifter bland annat om den registrerade invänder mot
        korrektheten av uppgifterna eller anser att behandlingen strider mot
        lag.
      </p>
      <p className="fst-italic fw-semibold">Rätt att göra invändningar</p>
      <p className="fst-italic">
        Den registrerade har rätt att invända mot behandling som grundar sig på
        ett berättigat intresse. Om personuppgifterna behandlas för direkt
        marknadsföring har den registrerade alltid rätt att när som helst
        invända mot behandlingen av sina personuppgifter för sådan
        marknadsföring, inklusive profilering av sådan direkt marknadsföring.
      </p>
      <p className="fst-italic fw-semibold">Rätt att återkalla samtycke</p>
      <p className="fst-italic">
        Den registrerade har rätt att när som helst återkalla sitt samtycke till
        behandlingen utan att det påverkar lagenligheten av den behandling som
        tidigare utförts med stöd av samtycket.
      </p>
      <p className="fst-italic fw-semibold">
        Rätt att flytta personuppgifter (dataportabilitet)
      </p>
      <p className="fst-italic">
        Den registrerade har rätt att få ut de personuppgifter som rör denne,
        samt de personuppgifter som den registrerade själv har lämnat in.
        Personuppgifterna ska lämnas ut i ett strukturerat, allmänt använt och
        maskinläsbart format. Den registrerade har vidare rätt att överföra
        dessa uppgifter till en annan personuppgiftsansvarig.
      </p>

      <p className="fst-italic fw-semibold">
        Rätt att lämna in klagomål till tillsynsmyndigheten
      </p>
      <p className="fst-italic">
        Den nationella tillsynsmyndigheten för frågor som rör personuppgifter är
        Integritetsskyddsmyndigheten. Den registrerade har rätt att överlåta
        sitt ärende till tillsynsmyndigheten om den registrerade anser att
        behandlingen av dennes personuppgifter bryter mot gällande lagstiftning.
      </p>
    </>
  );
};

export default PolicySweedish;
