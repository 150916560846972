import React, { useState } from 'react';
// import { MdEventSeat } from 'react-icons/md';

const Seat = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const isSelected = props.selectedSeat === props.number;
  const isAvailable = props.availability === "AVAILABLE";
  const color = isSelected ? "#8c6e33" : isAvailable ? "#C3A465" : "#ECECEC";
  const text_color = isSelected ? "#fff" : isAvailable ? "#fff" : "#000";
  const price = isAvailable
    ? props.price
      ? props.price.total
      : "0.00"
    : "N/A";
  const style = {
    position: "absolute",
    left: `${props.y * 2}em`,
    top: `${props.x * 2}em`,
    backgroundColor: color,
    color: text_color,
    cursor: "pointer",
    padding: "0.2em",
    zIndex: isHovered ? 1 : 0,
    borderRadius : '5px'
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (isAvailable) {
      props.onSeatSelect(props.number);
      console.log(price);
      console.log(props.price?.currency);
    }
  };

  return (
    <div
      className="seat d-flex align-items-center justify-content-center"
      style={style}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg width="23" height="20" viewBox="0 0 31 28" fill="none" style={{padding : '2px'}} xmlns="http://www.w3.org/2000/svg">
        <path d="M26.3586 7.94564V4.88042C26.3586 2.35162 24.2895 0.282593 21.7607 0.282593H9.49985C6.97105 0.282593 4.90203 2.35162 4.90203 4.88042V7.94564C2.37322 7.94564 0.304199 10.0147 0.304199 12.5435V20.2065C0.304199 22.7353 2.37322 24.8043 4.90203 24.8043V27.8696H7.96724V24.8043H23.2933V27.8696H26.3586V24.8043C28.8874 24.8043 30.9564 22.7353 30.9564 20.2065V12.5435C30.9564 10.0147 28.8874 7.94564 26.3586 7.94564ZM7.96724 4.88042C7.96724 4.03749 8.65692 3.34781 9.49985 3.34781H21.7607C22.6037 3.34781 23.2933 4.03749 23.2933 4.88042V9.14107C22.3584 9.98401 21.7607 11.1948 21.7607 12.5435V15.6087H9.49985V12.5435C9.49985 11.1948 8.90214 9.98401 7.96724 9.14107V4.88042ZM27.8912 20.2065C27.8912 21.0494 27.2015 21.7391 26.3586 21.7391H4.90203C4.05909 21.7391 3.36942 21.0494 3.36942 20.2065V12.5435C3.36942 11.7005 4.05909 11.0109 4.90203 11.0109C5.74496 11.0109 6.43464 11.7005 6.43464 12.5435V18.6739H24.8259V12.5435C24.8259 11.7005 25.5156 11.0109 26.3586 11.0109C27.2015 11.0109 27.8912 11.7005 27.8912 12.5435V20.2065Z" fill="white" />
      </svg>
      {isAvailable &&
        isHovered && (
          <div className="popover text-black d-flex z-index-101010">
            {`€${Number(parseFloat(price) + parseFloat(price * props.taxService))?.toFixed(2)}`}
          </div>
        )
      }
    </div>
  );
};

export default Seat;
