export default function hasSwedenFlight(itineraries) {
    
    const validAirports = ["AGH", "AJR", "ARN", "BLE", "BMA", "EKT", "EVG", "GEV", "GOT", "GSE", "GVX", "HAD", "HFS", "HLF", "HMV", "HUV", "IDB", "JHE", "JKG", "JLD", "JMM", "JSO", "KID", "KLR", "KRF", "KRN", "KSD", "KSK", "KVB", "LDK", "LLA", "LPI", "LYC", "MMX", "MXX", "NRK", "NYO", "OER", "ORB", "OSD", "OSK", "PJA", "RNB", "SDL", "SFT", "SOO", "SQO", "THN", "TYF", "UME", "VBY", "VHM", "VST", "VVK", "VXO", "XWP", "XWQ", "XWZ", "XXK", "XXU", "XYC", "XYI", "XYO", "XYU", "XYX"];

    if (!itineraries || !Array.isArray(itineraries)) return false;
  
    for (const itinerary of itineraries) {
      if (!itinerary || !itinerary.segments || !Array.isArray(itinerary.segments)) continue;
  
      for (const segment of itinerary.segments) {
        if (!segment || !segment.departure || !segment.arrival) continue;
  
        if (validAirports.includes(segment.departure.iataCode) && validAirports.includes(segment.arrival.iataCode)) {
          return true;
        }
      }
    }
  
    return false;
  }