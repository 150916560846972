import React, { useEffect, useState } from "react";
// import malysia from "./assets/qater_airways.png";
import { FaEye } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import apis from "../services/index";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import PassangerInfoModal from "./PassangerInfoModal";
import { cities } from "./common/cities";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";

const UpComingBookings = ({ setLoader, bookings, loader, getBooking }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [booking_id, set_booking_id] = useState(null);
  const filteredBookings = bookings?.filter((e) => e.status !== "cancelled");
  const booking_details = filteredBookings?.filter((e) => e._id === booking_id);
// city name
const getCityDataName = (iataCode) => {
  const [cityName] = cities.filter(c => c.iata_code === iataCode)
  return cityName ? cityName?.city : "";
};

  return (
    <>
      <PassangerInfoModal
        show={show}
        setShow={setShow}
        booking_details={booking_details?.[0]}
      />
      {loader && <div id="cover-spin"></div>}
      <div className="container">
        {!loader && filteredBookings?.length === 0 && (
          <h1 className="text-center my-5">
            <i>No Bookings Found</i>
          </h1>
        )}
        <div className="row">
          {filteredBookings?.length > 0 &&
            filteredBookings?.map((booking, i) => {
              return (
                <div
                  key={i}
                  className="flight-list-container col-12 bg-white my-2 col-md-6 col-lg-12 shadow-sm py-lg-4 my-3 rounded-3 d-flex flex-wrap flex-column flex-lg-row align-items-center  justify-content-between"
                >
                  {/* <img
                    src={malysia}
                    className="img-fluid airline_Logo mb-4 mb-lg-0"
                    alt=""
                  /> */}
                  <Logo
                    name={getCityDataName(booking?.routes[0]?.carrierCode)}
                    code={booking?.routes[0]?.carrierCode}
                    isBooking={true}
                  />

                  <div className="d-flex align-items-center my-2">
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="m-0 text-gold1">
                        {dayjs(booking?.routes[0]?.departure?.at).format(
                          "h:mm"
                        )}
                      </h5>
                      <p className="m-0">
                        {/* {booking?.routes[0]?.departure?.iataCode} */}
                        {
                          getCityDataName(
                            booking?.routes[0]?.departure?.iataCode
                          )
                            ? getCityDataName(
                                booking?.routes[0]?.departure?.iataCode
                              )
                            : ""
                          // getCityName(itinerary?.segments?.[0]?.departure?.iataCode)
                        }
                      </p>
                    </div>
                    <div className="arrowLine">
                      <svg
                        width="226"
                        height="9"
                        viewBox="0 0 226 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M225.354 4.85355C225.549 4.65829 225.549 4.34171 225.354 4.14645L222.172 0.964466C221.976 0.769204 221.66 0.769204 221.464 0.964466C221.269 1.15973 221.269 1.47631 221.464 1.67157L224.293 4.5L221.464 7.32843C221.269 7.52369 221.269 7.84027 221.464 8.03553C221.66 8.2308 221.976 8.2308 222.172 8.03553L225.354 4.85355ZM0 5H225V4H0V5Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                      <h5 className="m-0 text-gold1">
                        {dayjs(booking?.routes[0]?.arrival?.at).format("h:mm")}
                      </h5>
                      <p className="m-0">
                        {
                          getCityDataName(
                            booking?.routes[booking?.routes?.length - 1]
                              ?.arrival?.iataCode
                          )
                            ? getCityDataName(
                                booking?.routes[booking?.routes?.length - 1]
                                  ?.arrival?.iataCode
                              )
                            : "Unknown city"
                          // getCityName(itinerary?.segments?.[0]?.departure?.iataCode)
                        }
                      </p>
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-center">
                    <h6 className="m-0">Departure</h6>
                    <h5 className="m-0 fw-normal my-2">
                      {" "}
                      {dayjs(
                        booking?.routes[booking?.routes?.length - 1]?.departure
                          ?.at
                      ).format("DD-MM-YYYY")}
                    </h5>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h6 className="m-0">Status</h6>
                    <button
                      className={`btn ${
                        booking?.status === "paid"
                          ? "btn-success"
                          : booking?.status === "pending"
                          ? "btn-warning"
                          : ""
                      }  rounded-5 mt-2 text-uppercase`}
                    >
                      {booking?.status}
                    </button>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h6 className="m-0">Price</h6>
                    <p className="fs-6 mt-2 fw-semibold">
                      SEK {booking?.grandTotalWithAirProService}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h6 className="m-0">Details</h6>
                    <button
                      className="btn btn-primary border-0  bg_gold rounded-5 my-2"
                      onClick={() => {
                        set_booking_id(booking?._id);
                        setShow(true);
                      }}
                    >
                      <FaEye className="fw-bold" /> Details
                    </button>
                  </div>

                  <button
                    className="btn btn-danger rounded-5 mt-lg-3"
                    onClick={() =>
                      navigate("/contact-us", { state: { cancellation: true } })
                    }
                    // onClick={() => {
                    //   cancel_flight(booking?._id);
                    // }}
                  >
                    <IoClose className="fw-bold" /> Cancel
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default UpComingBookings;
