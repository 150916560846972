import React from "react";
import { SlBadge } from "react-icons/sl";
import { GiTicket } from "react-icons/gi";
import { TbWorldPin } from "react-icons/tb";
import boarding from "./assets/flitghBoarding.webp";
import flights from "./assets/flightsBook.jpeg";
import hotels from "./assets/hotels.jpg";
import vacations from "./assets/vacations.jpeg";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const MiddleContent = () => {
  return (
    <>
      <div className="home-sec-three">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left">
                <h3 className="fw-bold" id="hide-on-desktop">
                  Changing the way
                </h3>
                <div className="line" id="hide-on-desktop"></div>
                <h3 className="fw-bold" id="hide-on-desktop">
                  you travel
                </h3>
                <img
                  src="./assets/images/home-sec-three.png"
                  className=""
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 my-auto">
              <div className="right">
                <h3 className="fw-bold" id="hide-on-mobile">
                  Changing the way you travel
                </h3>
                <div className="line" id="hide-on-mobile"></div>
                <p>We’ve got your back with the flyproview</p>
                <ul>
                  <li>Book new trips and add changes to your current ones</li>
                  <li>Get through the airport quicker with terminal guide</li>
                  <li>Custom Lagguage</li>
                  <li>Custom Seat Selection</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-sec-four">
        <img src="./assets/images/home-sec-four.png" alt="" />
      </div>
      <div className="home-sec-five">
        <div className="container">
          <h3 className="title">Why use FlyProView</h3>
          <div className="line"></div>
          <p className="desc">
            Your shortcut to stress-free travel. Effortless bookings, limitless
            possibilities.
          </p>
          <div className="row">
            <div className="col-lg-4">
              <div className="sec-five-card">
                <div className="icon">
                  <svg
                    width="43"
                    height="43"
                    viewBox="0 0 43 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_83_1918)">
                      <path
                        d="M42.8766 35.2722L35.013 20.7301C35.8153 18.9094 36.2641 16.8971 36.2641 14.78C36.2641 6.62077 29.6495 0.00683594 21.4909 0.00683594C13.3303 0.00683594 6.71634 6.62144 6.71634 14.78C6.71634 16.9482 7.18733 19.0054 8.02583 20.8598L0.127265 35.2655C-0.127417 35.7284 -0.0850892 36.2995 0.23338 36.7221C0.55252 37.1434 1.09136 37.3416 1.60598 37.2207L8.35093 35.6734L10.6595 42.1032C10.8396 42.6031 11.2958 42.9498 11.8245 42.9887C11.8588 42.9914 11.8917 42.9928 11.924 42.9928C12.1669 42.9927 12.4053 42.9267 12.6138 42.8019C12.8223 42.6771 12.993 42.4981 13.1078 42.2839L19.9818 29.4774C20.4829 29.5281 20.9864 29.5535 21.4901 29.5533C21.9571 29.5533 22.4179 29.5291 22.8735 29.4861L29.8966 42.2941C30.0209 42.5211 30.2081 42.7074 30.4358 42.8305C30.6634 42.9536 30.9218 43.0083 31.1799 42.9881C31.7073 42.9457 32.1615 42.5997 32.3401 42.1025L34.6487 35.6727L41.3937 37.22C41.911 37.3449 42.4445 37.1441 42.7636 36.7241C43.0841 36.3049 43.1271 35.7365 42.8765 35.2722H42.8766ZM12.1684 38.3534L10.4779 33.6435C10.2468 33.0032 9.57631 32.6304 8.91245 32.7882L3.92378 33.9324L9.60994 23.5614C11.4825 26.091 14.1431 27.9991 17.229 28.9277L12.1684 38.3534ZM9.41573 14.78C9.41573 8.12237 14.8331 2.70572 21.4913 2.70572C28.1489 2.70572 33.5656 8.12237 33.5656 14.78C33.5656 21.4376 28.1489 26.8556 21.4913 26.8556C14.8324 26.8556 9.41573 21.4376 9.41573 14.78ZM34.087 32.7882C33.4205 32.631 32.7526 33.0032 32.5215 33.6435L30.8196 38.3876L25.6482 28.9578C28.8161 28.0299 31.5412 26.0715 33.4379 23.468L39.1005 33.9378L34.087 32.7882Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_83_1918">
                        <rect width="43" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <h4>More for less</h4>
                <p>
                  We offer e-tickets with exceptional discounted deal across the
                  country
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sec-five-card">
                <div className="icon">
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.6965 9.36292C24.9309 8.59709 23.8925 8.16674 22.8096 8.1665H8.16675V22.8093C8.16698 23.8922 8.59734 24.9307 9.36316 25.6963L23.6548 39.9879C24.4206 40.7534 25.459 41.1835 26.5417 41.1835C27.6245 41.1835 28.6629 40.7534 29.4287 39.9879L39.9882 29.4284C40.7537 28.6627 41.1837 27.6243 41.1837 26.5415C41.1837 25.4588 40.7537 24.4203 39.9882 23.6546L25.6965 9.36292Z"
                      stroke="#F5F5F5"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.375 18.375H18.3941V18.3941H18.375V18.375Z"
                      stroke="#F5F5F5"
                      stroke-width="3.75"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <h4>Lowest Fares</h4>
                <p>We provide affordable tickets to save up to 10%</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sec-five-card">
                <div className="icon">
                  <svg
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.625 25.4998C44.6251 21.7787 43.5397 18.1383 41.5018 15.0249C39.4639 11.9114 36.5619 9.46013 33.1516 7.97137C29.7413 6.48262 25.9707 6.02108 22.302 6.64331C18.6332 7.26555 15.2257 8.94453 12.497 11.4745C9.76826 14.0045 7.83686 17.2755 6.93947 20.8868C6.04208 24.4981 6.21769 28.2928 7.44478 31.8057C8.67187 35.3187 10.8971 38.3974 13.8479 40.6646C16.7986 42.9317 20.3466 44.2888 24.0571 44.5695M7.65 19.1248H43.35M7.65 31.8748H22.3125"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.4375 6.375C20.8576 12.1117 18.9597 18.738 18.9597 25.5C18.9597 32.262 20.8576 38.8883 24.4375 44.625M26.5625 6.375C29.8141 11.5822 31.6835 17.5328 31.994 23.664M38.25 46.75L45.3688 39.7715C45.8053 39.3479 46.1526 38.8411 46.39 38.2811C46.6275 37.721 46.7503 37.1191 46.7513 36.5108C46.7523 35.9025 46.6314 35.3002 46.3958 34.7394C46.1602 34.1786 45.8146 33.6707 45.3794 33.2456C44.4914 32.3772 43.2995 31.8899 42.0574 31.8875C40.8154 31.8851 39.6216 32.3679 38.7303 33.2329L38.2543 33.7004L37.7804 33.2329C36.8925 32.3651 35.701 31.8782 34.4595 31.8758C33.218 31.8734 32.0246 32.3558 31.1334 33.2201C30.6967 33.6436 30.3493 34.1503 30.1117 34.7102C29.874 35.2702 29.751 35.8721 29.7498 36.4804C29.7486 37.0887 29.8693 37.691 30.1047 38.2519C30.3402 38.8128 30.6856 39.3208 31.1207 39.746L38.25 46.75Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <h4>Discover</h4>
                <p>
                  We make travelling easy across Europe by providing easy
                  e-ticket.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-sec-six">
        <div className="container">
          <div className="title-line-button" id="hide-on-mobile">
            <div className="title-line">
              <h3>Enjoy Comfort</h3>
              <div className="line"></div>
            </div>
            <div className="button">
              <ul>
                <li className="arrow-right">
                  <svg
                    width="89"
                    height="89"
                    viewBox="0 0 89 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="89" height="89" rx="44.5" fill="white" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="88"
                      height="88"
                      rx="44"
                      stroke="black"
                      stroke-opacity="0.31"
                    />
                    <path
                      d="M51.175 31.1504L37.825 44.5004L51.175 57.8504"
                      stroke="#232339"
                      stroke-width="2"
                    />
                  </svg>
                </li>
                <li className="arrow-left">
                  <svg
                    width="89"
                    height="89"
                    viewBox="0 0 89 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="89"
                      y="89"
                      width="89"
                      height="89"
                      rx="44.5"
                      transform="rotate(-180 89 89)"
                      fill="white"
                    />
                    <rect
                      x="88.5"
                      y="88.5"
                      width="88"
                      height="88"
                      rx="44"
                      transform="rotate(-180 88.5 88.5)"
                      stroke="black"
                      stroke-opacity="0.31"
                    />
                    <path
                      d="M37.825 57.8496L51.175 44.4996L37.8251 31.1496"
                      stroke="#232339"
                      stroke-width="2"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
          <div className="title-line-button" id="hide-on-desktop">
            <div className="title-line">
              <h3>Enjoy Comfort</h3>
              <div className="line"></div>
            </div>
          </div>
          <p className="desc user-select-none ">
            Embark on a journey of seamless exploration with flyproview. We
            redefine the way you experience travel, offering a one-stop
            destination for effortless flight bookings.
          </p>
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            breakpoints={{
              400: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 1,
              },
              900: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 3,
              },
              1600: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={20}
            navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
            scrollbar={{ draggable: false }}
            pagination={false}
            onSwiper={(swiper) => console.log()}
          >
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-1.png" alt="" />
                <button>Book Flights</button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-2.png" alt="" />
                <button>Enjoy Vacations</button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-3.png" alt="" />
                <button>Stay in Hotels</button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-1.png" alt="" />
                <button>Book Flights</button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-2.png" alt="" />
                <button>Enjoy Vacations</button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sec-slider-card">
                <img src="./assets/images/slider-3.png" alt="" />
                <button>Stay in Hotels</button>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="title-line-button" id="hide-on-desktop">
            <div className="button">
              <ul>
                <li className="arrow-right">
                  <svg
                    width="89"
                    height="89"
                    viewBox="0 0 89 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="89" height="89" rx="44.5" fill="white" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="88"
                      height="88"
                      rx="44"
                      stroke="black"
                      stroke-opacity="0.31"
                    />
                    <path
                      d="M51.175 31.1504L37.825 44.5004L51.175 57.8504"
                      stroke="#232339"
                      stroke-width="2"
                    />
                  </svg>
                </li>
                <li className="arrow-left">
                  <svg
                    width="89"
                    height="89"
                    viewBox="0 0 89 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="89"
                      y="89"
                      width="89"
                      height="89"
                      rx="44.5"
                      transform="rotate(-180 89 89)"
                      fill="white"
                    />
                    <rect
                      x="88.5"
                      y="88.5"
                      width="88"
                      height="88"
                      rx="44"
                      transform="rotate(-180 88.5 88.5)"
                      stroke="black"
                      stroke-opacity="0.31"
                    />
                    <path
                      d="M37.825 57.8496L51.175 44.4996L37.8251 31.1496"
                      stroke="#232339"
                      stroke-width="2"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleContent;
