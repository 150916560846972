// src/utils/travelerValidation.js
import Joi from 'joi';

const travelerSchema = Joi.object({
  id: Joi.string().allow(''),
  dateOfBirth: Joi.date().required().messages({
    'any.required': 'Date of birth is required',
    'date.base': 'Date of birth must be a valid date'
  }),
  firstName: Joi.string().required().messages({
    'any.required': 'First name is required'
  }),
  lastName: Joi.string().required().messages({
    'any.required': 'Last name is required'
  }),
  gender: Joi.string().valid('MALE', 'FEMALE', 'UNSPECIFIED', 'UNDISCLOSED').required().messages({
    'any.required': 'Gender is required',
    'any.only': 'Invalid gender selection'
  }),
  emailAddress: Joi.string().email({ tlds: false }).required().messages({
    'any.required': 'Email address is required',
    'string.email': 'Invalid email address format'
  }),
  confirmEmailAddress: Joi.string().valid(Joi.ref('emailAddress')).required().messages({
    'any.required': 'Confirm email address is required',
    'any.only': 'Confirm email must match email address'
  }),
  deviceType: Joi.string().allow(''),
  email: Joi.string().allow(''),
  countryCallingCode: Joi.string().allow(''),
  number: Joi.string().required().messages({
    'any.required': 'Number is required'
  }),
  documentType: Joi.string().allow(''),
  birthPlace: Joi.string().allow(''),
  issuanceLocation: Joi.string().allow(''),
  issuanceDate: Joi.date().required().messages({
    'any.required': 'Issuance date is required',
    'date.base': 'Issuance date must be a valid date'
  }),
  documentNumber: Joi.string().allow(''),
  expiryDate: Joi.date().greater(Joi.ref('issuanceDate')).required().messages({
    'any.required': 'Expiry date is required',
    'date.base': 'Expiry date must be a valid date',
    'date.greater': 'Expiry date must be after issuance date'
  }),
  issuanceCountry: Joi.string().allow(''),
  validityCountry: Joi.string().allow(''),
  nationality: Joi.string().required().messages({
    'any.required': 'Nationality is required'
  }),
  isHolder: Joi.boolean(),
  phone: Joi.string().pattern(/^\d{8,}$/).required().messages({
    'any.required': 'Phone number is required',
    'string.pattern.base': 'Phone number must have at least 8 digits'
  })
});

const travelersArraySchema = Joi.array().items(travelerSchema).min(1);

export const validateTravelers = (travelers) => {
  const { error } = travelersArraySchema.validate(travelers, { abortEarly: false });
console.log(travelers,"travelers")
  if (error) {
    const errorDetails = error.details.map(detail => ({
      field: detail.path.join('.'),
      message: detail.message
    }));
    return { isValid: false, errors: errorDetails };
  }
  
  return { isValid: true };
};