import React, { useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import "react-date-picker/dist/DatePicker.css";
import "react-day-picker/dist/style.css";
import { format, addDays } from "date-fns";
export default function DatePickerDialog({
  checkInDate,
  returnDate,
  type,
  handleCheckInDateChange,
  handleReturnDateChange,
}) {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });
  // CLOSE date selection calander
  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };
  // date selection
  const handleDaySelect = (date) => {
    if (type === "depart") {
      console.log(date, "date");
      handleCheckInDateChange(date);
    } else {
      handleReturnDateChange(date);
    }
    closePopper();
  };

  const today = new Date();
  // disable the previus all dates
  const isDateDisabled = (date) => {
    if (type === "depart") {
      return date <= today;
    } else {
      return date <= checkInDate;
    }
  };

  // console.log(returnDate,"checkInDate")

  return (
    <div>
      <div ref={popperRef}>
        <input
          size={12}
          type="text"
          className="pt-0"
          readOnly
          value={format(
            type === "depart" ? checkInDate : returnDate,
            "dd-MMM-y"
          )}
        />
        <span className="hc" ref={buttonRef} onClick={handleButtonClick}>
          <svg
            width="22"
            height="22"
            className="arrow"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 2H15V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0C13.7348 0 13.4804 0.105357 13.2929 0.292893C13.1054 0.48043 13 0.734784 13 1V2H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10H18V17ZM18 8H2V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H5V5C5 5.26522 5.10536 5.51957 5.29289 5.70711C5.48043 5.89464 5.73478 6 6 6C6.26522 6 6.51957 5.89464 6.70711 5.70711C6.89464 5.51957 7 5.26522 7 5V4H13V5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6C14.2652 6 14.5196 5.89464 14.7071 5.70711C14.8946 5.51957 15 5.26522 15 5V4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V8Z"
              fill="grey"
            />
          </svg>
        </span>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current || undefined,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet bg-white shadow rounded-2 z-index_1000"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              minDate={type === "depart" ? new Date() : addDays(checkInDate, 1)}
              selected={type === "depart" ? checkInDate : returnDate}
              defaultMonth={type === "depart" ? checkInDate : returnDate}
              onSelect={handleDaySelect}
              disabled={isDateDisabled}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
