import React from "react";
import {  Triangle } from "react-loader-spinner";

const Loader = () => {
  return (
   <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
<Triangle
  height="80"
  width="80"
  color="#c3a465"
  ariaLabel="triangle-loading"
  wrapperStyle={{}}
  wrapperClassName=""
  visible={true}
/>
   </div>
  );
};

export default Loader;
