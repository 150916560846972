import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import AboutUsComp from "components/AboutUsComp";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About us</title>
      </Helmet>
      <Layout>
        <AboutUsComp />
        
      </Layout>
    </>
  );
};

export default AboutUs;
