import React from "react";
import { Link } from "react-router-dom";

const EnglishAgreement = () => {
  return (
    <>
      <h2>User Agreement</h2>
      <p>
        “ User Agreement for Electronic Services between Ultra Tech Sweden AB
        and Users of <Link to="/" className="text-decoration-underline">www.Flyproview.com</Link> “
      </p>
      <h4>1. Background</h4>
      <p>
        Ultra Tech Sweden AB ("Company") provides electronic services through
        its online travel agency platform, accessible via the website 
        <Link to="/" className="text-decoration-underline"> www.Flyproview.com</Link> ("Website"). By using the Website, including
        searching, booking, or visiting it, the user of the services ("User")
        enters into this user agreement ("Agreement") with the Company. All
        rights to design, unique features, and copyrights are owned by the
        Company, with organization number{" "}
        <span className="fw-bold"> 559441-8765</span>, and any attempts to copy
        or steal its design or features are prohibited according to applicable
        laws
      </p>
      <h4>2. Services and User Rights</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">2.1 </span>
        By using the Website and its services, the User acknowledges having
        entered into this Agreement with the Company. The User has the right to
        use the services provided on the Website according to this Agreement and
        agreed-upon terms.
      </p>

      <p className="mb-0">
        {" "}
        <span className="fw-bold">2.2 </span>
        The User can search, book, and perform travel activities through the
        Website. The User is responsible for accurate and correct information
        when booking and for complying with terms provided by third parties,
        including airlines and hotels.
      </p>
      <p>
        {" "}
        <span className="fw-bold">2.3 </span>
        The User does not have the right to copy, distribute, modify, reproduce,
        or use the Website's design, unique features, or other materials for
        commercial purposes or in ways that conflict with this Agreement or
        applicable laws.
      </p>

      <h4>3. Personal Data</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">3.1 </span>
        The use of personal data is regulated by the Company's privacy policy.
        By using the services, the User consents to the Company processing
        personal data according to this policy.
      </p>

      <p>
        {" "}
        <span className="fw-bold">3.2 </span>
        The Company is responsible for processing and protecting personal data
        in accordance with applicable data protection laws.
      </p>

      <h4>4. Limitation of Liability</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">4.1 </span>
        The Company is not liable for any losses or damages that the User may
        suffer due to improper use, errors, deficiencies in the services, or
        other circumstances. Any claims against the User arising from the use of
        the services lie with the User.
      </p>

      <p>
        {" "}
        <span className="fw-bold">4.2 </span>
        The Company is not responsible for changes or adaptations made by the
        User in connection with the use of the services. Any agreements and
        documents included in the services should be used as examples and may
        require individual adaptation for each specific case.
      </p>

      <h4>5. Copyright and Licensing</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">5.1 </span>
        The content on the Website, including design, features, and documents,
        is protected under applicable copyright laws. The User is granted the
        right to use the services and documents according to agreed-upon terms
        at the time of entering this Agreement.
      </p>

      <p>
        {" "}
        <span className="fw-bold">5.2 </span>
        The User does not have the right to copy, distribute, or use the
        Company's materials or services for commercial purposes or in ways that
        conflict with copyright laws or this Agreement.
      </p>

      <h4>6. Limitation to EU Citizens</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">6.1 </span>
        The Company only sells tickets and books hotel accommodations to
        individuals who are citizens of the European Union (EU). The User
        acknowledges that the services are intended exclusively for EU citizens.
      </p>

      <p>
        {" "}
        <span className="fw-bold">6.2 </span>
        The Company is not responsible if the system is used in a way that
        allows non-EU citizens to access the services. The User agrees that any
        unauthorized use of the services by non-EU citizens is not the
        responsibility of the Company.
      </p>

      <h4>7. Disputes and Applicable Law</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">7.1 </span>
        This Agreement and the use of the services are governed by Swedish law
      </p>

      <p>
        {" "}
        <span className="fw-bold">7.2 </span>
        Any disputes arising from this Agreement shall be resolved in a general
        court at Stockholm District Court
      </p>

      <p>
        By using the Website and its services, the User agrees to this Agreement
        and undertakes to comply with its terms.
      </p>
    </>
  );
};

export default EnglishAgreement;
