import React from "react";
import logo from "./assets/fly_pro_view.png";
import {
  SiFacebook,
  SiLinkedin,
  SiInstagram,
  SiTwitter,
  SiYoutube,
} from "react-icons/si";
import { Link } from "react-router-dom";
/**
 * Renders the footer component for the application.
 * The footer includes the company logo, navigation links, support information, and social media links.
 * The copyright notice is also displayed in the footer.
 */

const Footer = () => {
  const d = new Date();
  return (
    <div className="container-fluid bg-dark">
      <div className="container pt-5 pb-2">
        <div className="row">
          <div className="col-sm-6 col-md-3 mt-4 mt-lg-0">
            <img src={logo} className="img-fluid footerLogo d-block mx-auto mx-lg-0" alt="" />
            <p className="text-light fw-semibold text-center text-lg-start">We provide affordable tickets</p>
          </div>
          <div className="col-sm-6 col-md-3 mt-4 mt-lg-0 text-light text-center text-lg-start">
            <h5 className="text-uppercase fw-semibold">Pages</h5>
            <Link to="/">
            <h6 className="mt-4 h_p">Home</h6>
            </Link>
            <Link to="/">
            <h6 className="mt-3 h_p">Flights</h6>
            </Link>
            <Link to="/about-us">
            <h6 className="mt-3 h_p">About Us</h6>
            </Link>
            <Link to="/contact-us">
            <h6 className="mt-3 h_p">Contact Us</h6>
            </Link>
          </div>
          <div className="col-sm-6 col-md-3 mt-4 mt-lg-0 text-light text-center text-lg-start">
            <h5 className="text-uppercase fw-semibold">Support</h5>
            <Link to="/contact-us">
            <h6 className="mt-4 h_p">Contact Us</h6>
            </Link>
            <Link to="/community">
            <h6 className="mt-3 h_p">Community</h6>
            </Link>
            <Link to="/terms_conditions">
            <h6 className="mt-3 h_p">Terms & Conditions</h6>
            </Link>
          </div>
          <div className="col-sm-6 col-md-3 mt-4 mt-lg-0 text-light">
            <h5 className="text-uppercase fw-semibold text-center text-lg-start">Social Media</h5>
            <div className="d-flex justify-content-between mt-4">
              <SiFacebook className="fs-3 h_p" />
              <SiLinkedin className="fs-3 h_p" />
              <SiInstagram className="fs-3 h_p" />
              <SiTwitter className="fs-3 h_p" />
              <SiYoutube className="fs-3 h_p" />
            </div>
          </div>
        </div>
        <hr className="mt-4" />
        <p className="text-center text-uppercase mt-4 text-secondary">
          Copyright © {d.getFullYear()} flyproview | All Right Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
