import React from "react";
import Modal from "react-bootstrap/Modal";

const PassangerInfoModal = ({ show, setShow, booking_details }) => {
  return (
    <Modal show={show} onHide={setShow} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Traveller Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {booking_details?.travelers?.map((passanger, i) => {
            let { name, contact, documents, dateOfBirth } = passanger;
            return (
              <>
                <h3>Traveller {i + 1} Information</h3>
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Name</h6>
                  <h5 className="m-0">
                    {name?.firstName} {name?.lastName}
                  </h5>
                </div>

                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Email Address</h6>
                  <h5 className="m-0 wordbreak">{contact?.emailAddress}</h5>
                </div>

                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Contact</h6>
                  <h5 className="m-0">
                    
                    {contact?.phones?.[0]?.number}
                  </h5>
                </div>
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Date of Birth</h6>
                  <h5 className="m-0">{dateOfBirth}</h5>
                </div>
              
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Document Type</h6>
                  <h5 className="m-0">{documents?.[0]?.documentType}</h5>
                </div>
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Expiry</h6>
                  <h5 className="m-0">{documents?.[0]?.expiryDate}</h5>
                </div>
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Issuance Date</h6>
                  <h5 className="m-0">{documents?.[0]?.issuanceDate}</h5>
                </div>
                <div className="col-md-4 my-3">
                  <h6 className="m-0 text-secondary">Nationality</h6>
                  <h5 className="m-0">{documents?.[0]?.nationality}</h5>
                </div>
                <hr />
              </>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PassangerInfoModal;
