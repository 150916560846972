import React from "react";
import CommunityForm from "components/CommunityForm";
import Layout from "../components/Layout";
// import logo from "../../         /assets/fly_pro_view.png";
import logo from "../components/assets/fly_pro_view.png";
import community from "../components/assets/communityImage.png";
import ThanksModal from "../components/Modal/ThanksModal";
const Community = () => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <Layout>
      <ThanksModal showModal={showModal} setShowModal={setShowModal} />
      {/* <div className="communityBanner">
        <h1 className="font-playfair text-white mainHeading">Community</h1>
      </div> */}
      <div className="container">
        <img
          src={community}
          className="img-fluid d-block mx-auto my-3"
          alt=""
        />

        <h1 className="text-center fw-bolder mt-5 headings">
          🌟 Join Our Innovation Journey! 🌍✈️
        </h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            Welcome to the future of travel! At Flyproview.com, we’re on a
            mission to revolutionize the way you experience and plan your trips.
            Your insights are invaluable, and we want YOU to be an integral part
            of our journey.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">🚀 Why Join?</h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            - <span className="fw-bold">Shape the Future :</span> Your opinions
            matter! Help us design and develop features that cater to your
            travel needs.
          </h5>
          <h5 className="text-secondary text-center fw-normal ">
            - <span className="fw-bold">Exclusive Previews :</span> Be the first
            to experience and provide feedback on new features and innovations
            before anyone else.
          </h5>
          <h5 className="text-secondary text-center fw-normal ">
            - <span className="fw-bold">Tailored for You :</span> We're building
            this platform with YOU in mind. Your input ensures a personalized
            and customer-focused travel experience.
          </h5>
        </div>

        <h1 className="text-center fw-bolder mt-5 headings">
          🌟 Early Birds Bonus - Join the Golden Community! 🌟
        </h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            Sign up now to become a part of our exclusive Golden Community. As
            an honored member, you'll enjoy special treatments and perks when
            we're online, ensuring a VIP experience every time you explore the
            world with us.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">🔒 How to Join:</h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            Simply fill out the form below to secure your spot in the Golden
            Community. Together, let's create the ultimate online travel agency
            that redefines the way we explore the world.
          </h5>
        </div>
      </div>
      <CommunityForm setShowModal={setShowModal} />
      <h1 className="text-center fw-bolder my-5 headings">
        ✨ Thank you for being a pioneer in travel innovation! ✨
      </h1>
      <img src={logo} className="img-fluid d-block mx-auto mb-3" alt="" />
    </Layout>
  );
};

export default Community;
