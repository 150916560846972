import React from "react";
import FlightDetails from "components/FlightDetails";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const FlightDetail = () => {
  return (
    <Layout>
      <Helmet>
        <title>Flight Detail</title>
      </Helmet>
      <FlightDetails />
    </Layout>
  );
};

export default FlightDetail;
