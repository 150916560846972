import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import paymentImage from "../assets/payment_proceding.png";


const SuccessModal = ({ showModal ,handleToggleModal }) => {
  return (
    <Modal 
    show={showModal}
    size="lg"
    onHide={handleToggleModal}
    backdrop="static"
    keyboard={false}
    centered 
   >
    <Modal.Header closeButton></Modal.Header>   
    <Modal.Body>
      <h3 class="text-center my-5 ">Your Payment is being Successfull</h3>
      <img
        src={paymentImage}
        class="img-fluid paymentImage d-block mx-auto mb-4"
        alt=""
      />
    </Modal.Body>
  </Modal>
    );
};

export default SuccessModal;
