import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apis from "services";
import { toast } from "react-hot-toast";
import { setUser } from "redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";

const OtpComp = () => {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (user) {
      navigate("/flight_search");
    }
  }, []);

  useEffect(() => {
    if (!state?.email) {
      navigate("/login");
    }
  }, []);

  const [otp, setOtp] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
// submit otp funtionally
  const submitHandler = async () => {
    try {
      setLoading(true);
      const response = await apis.authOtp({
        email: state?.email,
        otp: otp,
      });
      if (response?.data?.status) {
        toast.success(response?.data?.message, { id: 1 });

        if (state?.type === "forgot") {
          setTimeout(() => {
            navigate("/update_password", { state: { email: state?.email } });
          }, 2000);
        } else {
          dispatch(setUser(response?.data?.user));
          setTimeout(() => {
            navigate("/flight_search");
          }, 2000);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e?.response?.data?.message, { id: 1 });
    }
  };

  useEffect(() => {
    if (otp?.length === 4) {
      submitHandler();
    }
  }, [otp]);
  return (
    <div className="container-fluid p-0 bg-otp d-flex align-items-center ">
      <div className="container rounded-4 contents">
        <div className="row align-items-center my-3">
          <div className="mx-auto col-md-6 col-lg-4 my-3">
            <form>
              <div className="row bg-white p-3 py-4 rounded-3 ">
                <h1 className="fw-bold text-center">Verify OTP</h1>
                <h6 className="fw-normal text-secondary text-center">
                  Let's Verify for your vacation with loved ones
                </h6>
                <div className="col-12 mt-3 p-0">
                  <p className="m-0 text-secondary text-center">OTP CODE</p>
                  {/* <input
                  type="number"
                  minLength={4}
                  maxLength={4}
                  id="otp"
                  required
                  onChange={onChangeHandler}
                  className="hotel_form_input px-2"
                  placeholder="****"
                /> */}
                  <div className="d-flex justify-content-center align-items-center">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputStyle="otp__input"
                      inputType="number"
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>

                <p className="text-center text-secondary m-0 mt-3">
                  Check Your Email. {state?.email}
                </p>
                <p className="text-center text-secondary m-0 mt-3">
                  OPT code is on Email
                </p>
                <div className="d-flex justify-content-center mt-3">
                  {loading && (
                    <Spinner animation="border" variant="primary" size="sm" />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpComp;
