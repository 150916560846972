import React, { useEffect, useState } from "react";
import { Search } from "./Search";
import apis from "services";
import { toast } from "react-hot-toast";
import { CustomDropdownOne } from "./shared/CustomDropdownOne";
import { ThreeDots } from "react-loader-spinner";
import DatePickerDialog from "./DatePopup";
import { PassangerDropDown } from "./shared/PassangerDropDown";
import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import format from "date-fns/format";
import { GiBackForth } from "react-icons/gi";
// const { isAfter, format } = require("date-fns");
const FlightSearchForm = ({
  setSelectedStops,
  selectedStops,
  setOthersData,
  setData,
  setLoading,
  loading,
  setInputValueFrom,
  inputValueFrom,
  setInputValueTo,
  inputValueTo,
  setSearchStart,
  ip,
  currency,
}) => {
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(addDays(new Date(), 1));

  useEffect(() => {
    setCheckInDate(new Date());
    setReturnDate(addDays(new Date(), 1));
  }, []);

  const [cityCurrentCode, setCityCurrentCode] = useState(
    sessionStorage.getItem("fromCode")
  );
  const [cityDestinationCode, setCityDestinationCode] = useState(
    sessionStorage.getItem("toCode")
  );

  //eslint-disable-next-line
  const [flightMode, setFlightMode] = useState(false);
  const [flightReturnMode, setFlightReturnMode] = useState(
    JSON.parse(sessionStorage.getItem("flightReturnMode")) || {
      lable: "Round Trip",
      value: "true",
    }
  );

  const [travelClass, setTravelClass] = useState(
    JSON.parse(sessionStorage.getItem("travelClass")) || {
      lable: "Economy Class",
      value: "ECONOMY",
    }
  );
  // swap city names with each other
  const swapCity = () => {
    let fromcity = inputValueFrom;
    let tocity = inputValueTo;
    let toCode = cityCurrentCode;
    let fromCode = cityDestinationCode;
    setCityCurrentCode(fromCode);
    setCityDestinationCode(toCode);
    setInputValueFrom(tocity);
    setInputValueTo(fromcity);
  };

  const [adults, setAdults] = useState(
    Number(sessionStorage.getItem("adults")) || 1
  );
  const [childrens, setChildrens] = useState(
    Number(sessionStorage.getItem("childs")) || 0
  );
  const [infant, setInfant] = useState(
    Number(sessionStorage.getItem("infants")) || 0
  );

  let idCounter = 1;
  // adult array
  const adultsArray = Array.from({ length: adults }, () => ({
    id: (idCounter++).toString(),
    travelerType: "ADULT",
    fareOptions: ["STANDARD"],
  }));
  // child array
  const childrenArray = Array.from({ length: childrens }, () => ({
    id: (idCounter++).toString(),
    travelerType: "CHILD",
    fareOptions: ["STANDARD"],
  }));

  const infantStartId = idCounter;
  // infant array
  const infantArray = Array.from({ length: infant }, (_, index) => ({
    id: (infantStartId + index).toString(),
    travelerType: "HELD_INFANT",
    fareOptions: ["STANDARD"],
    associatedAdultId: adultsArray[index]?.id,
  }));
  // combination of all arays
  const travellerArray = adultsArray.concat(childrenArray, infantArray);

  const data = {
    first: {
      icon: `
      
      <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="37" rx="7" fill="#C3A465" />
    <path d="M10.3125 18.5L8.39583 16.5833C8.39583 16.5833 7.725 18.8488 7.88025 20.2144C8.0355 21.5801 9.48546 22.6084 10.7917 21.8541C12.0984 21.0999 27.0833 11.7916 27.0833 11.7916L22.7708 10.8333L10.3125 18.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.4584 13.2292L11.1741 12.4553L9.83337 13.2292L13.1875 16.5833M19.8959 28.0833L17.9792 25.6875H26.125V23.7708M21.3334 20.4167L23.25 22.8125H15.1042V24.7292" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>`,
      fieldData: [
        {
          lable: "Round Trip",
          value: "true",
        },
        {
          lable: "One Way",
          value: "false",
        },
      ],
    },
    second: {
      icon: `
      <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="36" height="37" rx="7" fill="#C3A465" />
                    </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.07 10.41C13.6774 9.56125 14.0041 8.54371 14.0041 7.49999C14.0041 6.45628 13.6774 5.43873 13.07 4.58999C13.6385 4.20195 14.3117 3.99616 15 3.99999C15.9283 3.99999 16.8185 4.36874 17.4749 5.02512C18.1313 5.6815 18.5 6.57173 18.5 7.49999C18.5 8.42825 18.1313 9.31849 17.4749 9.97486C16.8185 10.6312 15.9283 11 15 11C14.3117 11.0038 13.6385 10.798 13.07 10.41ZM5.5 7.49999C5.5 6.80776 5.70527 6.13107 6.08986 5.5555C6.47444 4.97992 7.02107 4.53132 7.66061 4.26641C8.30015 4.00151 9.00388 3.93219 9.68282 4.06724C10.3617 4.20229 10.9854 4.53563 11.4749 5.02512C11.9644 5.5146 12.2977 6.13824 12.4327 6.81718C12.5678 7.49611 12.4985 8.19984 12.2336 8.83938C11.9687 9.47892 11.5201 10.0256 10.9445 10.4101C10.3689 10.7947 9.69223 11 9 11C8.07174 11 7.1815 10.6312 6.52513 9.97486C5.86875 9.31849 5.5 8.42825 5.5 7.49999ZM7.5 7.49999C7.5 7.79666 7.58797 8.08667 7.7528 8.33335C7.91762 8.58002 8.15189 8.77228 8.42597 8.88581C8.70006 8.99934 9.00166 9.02905 9.29264 8.97117C9.58361 8.91329 9.85088 8.77043 10.0607 8.56065C10.2704 8.35087 10.4133 8.0836 10.4712 7.79263C10.5291 7.50166 10.4994 7.20006 10.3858 6.92597C10.2723 6.65188 10.08 6.41761 9.83335 6.25279C9.58668 6.08797 9.29667 5.99999 9 5.99999C8.60218 5.99999 8.22064 6.15803 7.93934 6.43933C7.65804 6.72064 7.5 7.10217 7.5 7.49999ZM16 17V19H2V17C2 17 2 13 9 13C16 13 16 17 16 17ZM14 17C13.86 16.22 12.67 15 9 15C5.33 15 4.07 16.31 4 17M15.95 13C16.5629 13.4767 17.064 14.0818 17.4182 14.7729C17.7723 15.4639 17.9709 16.2241 18 17V19H22V17C22 17 22 13.37 15.94 13H15.95Z" fill="white" />
    </svg>`,
      fieldData: [
        {
          lable: "1 Person",
          value: 1,
        },
        {
          lable: "2 Person",
          value: 2,
        },
        {
          lable: "3 Person",
          value: 3,
        },
        {
          lable: "4 Person",
          value: 4,
        },
        {
          lable: "5 Person",
          value: 5,
        },
      ],
    },
    three: {
      icon: `
      <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="36" height="37" rx="7" fill="#C3A465" />
                    </svg>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8125 1.83331C16.1435 1.83331 16.4713 1.89852 16.7771 2.0252C17.083 2.15188 17.3609 2.33757 17.595 2.57165C17.829 2.80573 18.0147 3.08362 18.1414 3.38947C18.2681 3.69531 18.3333 4.02311 18.3333 4.35415V17.6458C18.3333 17.9769 18.2681 18.3047 18.1414 18.6105C18.0147 18.9163 17.829 19.1942 17.595 19.4283C17.3609 19.6624 17.083 19.8481 16.7771 19.9748C16.4713 20.1014 16.1435 20.1666 15.8125 20.1666H6.18746C5.85634 20.1666 5.52847 20.1014 5.22257 19.9747C4.91667 19.8479 4.63873 19.6622 4.40464 19.428C4.17054 19.1938 3.98488 18.9158 3.85825 18.6099C3.73162 18.3039 3.66651 17.976 3.66663 17.6449V4.35415C3.66663 3.19915 4.44213 2.22748 5.49996 1.92773V3.43748C5.22129 3.64648 5.04163 3.97831 5.04163 4.35415V17.644C5.04163 18.2765 5.55496 18.7898 6.18746 18.7898H15.8125C16.445 18.7898 16.9583 18.2765 16.9583 17.644V4.35415C16.9583 3.72165 16.445 3.20831 15.8125 3.20831H13.75V1.83331H15.8125ZM12.8333 1.83331V9.29406C12.8333 9.97881 12.1 10.2355 11.6508 9.9944L11.5756 9.94673L9.62679 8.76881L7.72196 9.91556C7.26363 10.2456 6.50279 10.0439 6.42304 9.40406L6.41663 9.29498V1.83331H12.8333ZM11.4583 3.20831H7.79163V8.27106L9.23354 7.40115C9.34842 7.34007 9.47584 7.30632 9.60589 7.3025C9.73594 7.29869 9.86511 7.32491 9.98338 7.37915L11.4592 8.27198L11.4583 3.20831Z" fill="white" />
    </svg>`,
      fieldData: [
        {
          lable: "Economy",
          value: "ECONOMY",
        },
        {
          lable: "Premium Economy",
          value: "PREMIUM_ECONOMY",
        },
        {
          lable: "Business",
          value: "BUSINESS",
        },

        {
          lable: "First",
          value: "FIRST",
        },
      ],
    },
  };
  // handle checkin date
  const handleCheckInDateChange = (dateValue) => {
    setCheckInDate(dateValue);
    if (flightReturnMode.value && isAfter(dateValue, returnDate)) {
      setReturnDate(addDays(dateValue, 1));
    }
  };
  // handle return date change
  const handleReturnDateChange = (date) => {
    // setReturnDate(dayjs(date));
    setReturnDate(date);
  };

  const fromCode = sessionStorage.getItem("fromCode");
  const toCode = sessionStorage.getItem("toCode");
  const departureDate = sessionStorage.getItem("departureDate");
  const arrivalDate = sessionStorage.getItem("arrivalDate");
  const adultsTraveller = sessionStorage.getItem("adults");
  const childsTraveller = sessionStorage.getItem("childs");

  useEffect(() => {
    setCityCurrentCode(fromCode);
    // if user return to home then refresh the page with fresh data
    const fetchData = async () => {
      if (
        fromCode !== null &&
        toCode !== null &&
        departureDate !== null &&
        arrivalDate !== null &&
        adultsTraveller !== null &&
        childsTraveller !== null
      ) {
        setCityCurrentCode(fromCode);
        setCityDestinationCode(toCode);
        setAdults(Number(adultsTraveller));
        setChildrens(Number(childsTraveller));
        setCheckInDate(departureDate);
        setReturnDate(arrivalDate);
        setLoading(true);
        setSearchStart(true);
        try {
          const response = await apis.searchFlight(
            fromCode,
            toCode,
            format(departureDate, "y-MM-dd"),
            adultsTraveller,
            flightMode,
            travelClass.value,
            flightReturnMode.value,
            format(arrivalDate, "y-MM-dd"),
            travellerArray,
            currency,
            ip
          );

          if (response?.data) {
            if (response?.data?.meta?.count === 0) {
              setData([]);
            } else {
              setData(response?.data?.data);
              setOthersData(response?.data?.dictionaries);

              if (response?.data?.data?.length > 0) {
                sessionStorage.setItem(
                  "data",
                  JSON.stringify(response?.data?.data)
                );
                sessionStorage.setItem(
                  "othersData",
                  JSON.stringify(response?.data?.dictionaries)
                );
              } else {
                sessionStorage.setItem("data", JSON.stringify([]));
                sessionStorage.setItem("othersData", JSON.stringify([]));
              }
            }
          }
          setLoading(false);
        } catch (e) {
          setSearchStart(true);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  // submiting normal Search
  const onSubmitHandler = async () => {
    if (flightReturnMode.value === "true" && returnDate === "") {
      toast.error("Required fields empty");
    } else if (!cityCurrentCode && !cityDestinationCode) {
      toast.error("Required fields empty");
    } else if (cityCurrentCode === cityDestinationCode) {
      toast.error(
        "Please ensure that the departure and destination locations are different."
      );
    } else {
      try {
        setLoading(true);
        setSearchStart(true);
        const response = await apis.searchFlight(
          cityCurrentCode,
          cityDestinationCode,
          // checkInDate?.format("YYYY-MM-DD"),
          format(checkInDate, "y-MM-dd"),
          // checkInDate?.format("YYYY-MM-DD"),
          adults,
          flightMode,
          travelClass.value,
          flightReturnMode.value,
          // returnDate?.format("YYYY-MM-DD")
          format(returnDate, "y-MM-dd"),
          // returnDate?.format("YYYY-MM-DD"),
          travellerArray,
          currency,
          ip
        );

        if (response?.data) {
          setData(response?.data?.data);
          setOthersData(response?.data?.dictionaries);
          if (response?.data?.meta?.count === 0) {
            setData([]);
          }
          if (response?.data?.data?.length > 0) {
            sessionStorage.setItem("fromCode", cityCurrentCode);
            sessionStorage.setItem("toCode", cityDestinationCode);
            sessionStorage.setItem("adults", adults);
            sessionStorage.setItem("childs", childrens);
            sessionStorage.setItem("infants", infant);
            sessionStorage.setItem("from", inputValueFrom);
            sessionStorage.setItem("to", inputValueTo);
            sessionStorage.setItem("departureDate", checkInDate);
            sessionStorage.setItem("arrivalDate", returnDate);
            sessionStorage.setItem(
              "flightReturnMode",
              JSON.stringify(flightReturnMode)
            );
            sessionStorage.setItem("travelClass", JSON.stringify(travelClass));

            sessionStorage.setItem(
              "data",
              JSON.stringify(response?.data?.data)
            );
            sessionStorage.setItem(
              "othersData",
              JSON.stringify(response?.data?.dictionaries)
            );
          } else {
            sessionStorage.setItem("data", JSON.stringify([]));
            sessionStorage.setItem("othersData", JSON.stringify([]));
          }
        }

        setLoading(false);
      } catch (e) {
        setSearchStart(true);
        setLoading(false);
        console.log(e?.message, "Error");
      }
    }
  };

  return (
    <div className="flight__form_wrapper">
      <div className="flight__form_wrapper-wrap">
        <h3>Find best flight and where are you going today?</h3>
        <div className="one">
          <div className="one-wrap">
            <CustomDropdownOne
              data={data.first}
              setValue={setFlightReturnMode}
              value={flightReturnMode}
            />
            <PassangerDropDown
              data={data.second}
              setValue={setAdults}
              value={adults}
              adults={adults}
              setAdults={setAdults}
              childrens={childrens}
              setChildrens={setChildrens}
              setInfant={setInfant}
              infant={infant}
            />
            <CustomDropdownOne
              data={data.three}
              setValue={setTravelClass}
              value={travelClass}
            />
            {/* <div class="left">
              {flightReturnMode === "true" ? (
              <IoMdRepeat class="fa fa-refresh fs-3 me-1" />
            ) : (
              <MdKeyboardTab class="fa fa-refresh fs-3 me-1" />
            )}
            <select
              onChange={(e) => setFlightReturnMode(e.target.value)}
              name=""
              id=""
              class="hotel_dropdown pe-3 my-2"
            >
              <option value={"true"}>Round Trip</option>
              <option value={"false"}>One Way</option>
            </select>
            </div> */}
            {/* <div class="center">
              <BiSolidUser class="fa fa-user-o fs-3 me-1" />
            <select
              onChange={(e) => setAdults(e.target.value)}
              value={adults}
              name=""
              id=""
              class="hotel_dropdown pe-3"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            </div> */}
            {/* <div class="right">
              <MdFlightClass class="fs-3 me-1" />
            <select
              onChange={(e) => setTravelClass(e.target.value)}
              name=""
              defaultChecked={"Economy Class"}
              id=""
              class="hotel_dropdown pe-2"
            >
              <option value="ECONOMY">Economy Class</option>
              <option value="BUSINESS">BUSINESS Class</option>
            </select>
            </div> */}
          </div>
        </div>
        <div className="two">
          <div className="two-1">
            <label>From</label>
            <div className="position-relative w-100">
              <Search
                type={"departure"}
                cityDestinationCode={cityDestinationCode}
                cityCurrentCode={cityCurrentCode}
                setCityCode={setCityCurrentCode}
                setInputValue={setInputValueFrom}
                inputValue={inputValueFrom}
                value={"city or airport"}
              />
              <GiBackForth onClick={swapCity} className="reverseFlight" />
            </div>
          </div>
          <div className="two-2">
            <label>To</label>
            <div className="position-relative w-100">
              <Search
                type={"arrival"}
                cityDestinationCode={cityDestinationCode}
                cityCurrentCode={cityCurrentCode}
                setInputValue={setInputValueTo}
                inputValue={inputValueTo}
                setCityCode={setCityDestinationCode}
                value={"city or airport"}
              />
            </div>
          </div>

          <div
            className={`two-3 ${
              flightReturnMode.value === "true" ? "" : "two-3-single"
            }`}
          >
            <label>Depart</label>
            <div className="date-wrap">
              <DatePickerDialog
                checkInDate={checkInDate}
                returnDate={returnDate}
                type={"depart"}
                handleCheckInDateChange={handleCheckInDateChange}
                handleReturnDateChange={handleReturnDateChange}
              />
            </div>
          </div>

          {flightReturnMode.value === "true" && (
            <div className="two-4">
              <label>Return</label>
              {
                <div className="date-wrap">
                  <DatePickerDialog
                    checkInDate={checkInDate}
                    returnDate={returnDate}
                    type={"return"}
                    handleCheckInDateChange={handleCheckInDateChange}
                    handleReturnDateChange={handleReturnDateChange}
                  />
                </div>
              }
            </div>
          )}
          {/* </LocalizationProvider> */}
          <div className="two-5">
            <button
              disabled={loading}
              onClick={onSubmitHandler}
              className="d-flex justify-content-center align-items-center"
            >
              {loading ? (
                // <Spinner animation="border" variant="warning" size="sm" />
                <ThreeDots
                  visible={true}
                  width="60"
                  color="#fff"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <>Search</>
              )}
            </button>
          </div>
        </div>
        <div className="three">
          <ul>
            <li>
              <input
                type="checkbox"
                checked={selectedStops === "Directed"}
                onChange={(e) => {
                  selectedStops !== "Directed"
                    ? setSelectedStops("Directed")
                    : setSelectedStops("All");
                }}
              />
            </li>
            <li>Direct flights</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FlightSearchForm;
