import React, { useEffect, useState } from "react";
import Cancelled from "components/Cancelled";
import Layout from "../components/Layout";
import UpComingBookings from "components/UpComingBookings";
import { useSelector } from "react-redux";
import apis from "services";


const Bookings = () => {
  const [bookings, setBookings] = useState("upcoming");
  const { _id } = useSelector((e) => e?.user?.user);
  const [bookingsList, setBookingsList] = useState([]);
  const [loader, setLoader] = useState(false);



  // Get all user bookings
  const getBooking = async () => {
    if (_id) {
      try {
        setLoader(true);
        const response = await apis.get_all_flight_bookings(_id);
        setBookingsList(response?.data?.booking);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error?.message?.message, "flightsError");
      }
    }
  };

  useEffect(() => {
    getBooking();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className="container mt-5 pt-2 d-flex flex-wrap justify-content-center align-items-center">
        <button
          className={`m-2 shadow-sm ${
            bookings === "upcoming" ? "airLineBtn_active" : "airLineBtn"
          } `}
          onClick={() => setBookings("upcoming")}
        >
          UpComing
        </button>

        <button
          className={`m-2 shadow-sm ${
            bookings === "cancelled" ? "airLineBtn_active" : "airLineBtn"
          } `}
          onClick={() => setBookings("cancelled")}
        >
          Cancelled
        </button>
      </div>

      {bookings === "upcoming" && (
        <UpComingBookings
          loader={loader}
          setLoader={setLoader}
          getBooking={getBooking}
          setBookings={setBookings}
          bookings={bookingsList}
        />
      )}
      {bookings === "cancelled" && (
        <Cancelled loader={loader} bookings={bookingsList} />
      )}

     
    </Layout>
  );
};

export default Bookings;
