import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/Store";

//  StyleSheets
import "index.css";
import "styles/styles.css";
import "styles/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/purpleStyle.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
        <Toaster />
      </Router>
    </PersistGate>
  </Provider>
);
