import React from "react";
import Modal from "react-bootstrap/Modal";
import planeImg from "../assets/goldenJet.jpg";
import { useNavigate } from "react-router-dom";
import { cities } from "components/common/cities";
import { ThreeCircles } from "react-loader-spinner";
import moment from "moment";
const TicketModal = ({
  showTicketModal,
  loader,
  setShowTicketModal,
  ticketData,
}) => {
  const navigate = useNavigate();
  const getCityDataName = (iataCode) => {
    const [cityName] = cities.filter((c) => c.iata_code === iataCode);
    return cityName ? cityName?.city : "";
  };

  // console.log("end modal......");

  // on close modal go to home page
  let closeTicketModal = () => {
    setShowTicketModal(false);
    navigate("/");
  };

  let ticket = ticketData || {};
  let departureRoute = ticket?.routes;
  let returnRoute = ticket?.returnRoutes;
  console.log(departureRoute, "routes");

  return (
    <>
      <Modal show={showTicketModal} size="" onHide={closeTicketModal} centered>
        <Modal.Header className="bg-gold border-0 " closeButton></Modal.Header>
        <Modal.Body className="p-0 modal_pay">
          <div className="position-relative">
            <img src={planeImg} alt="" className="img-fluid payModalplane" />
            <h4 className="ms-2 position-absolute top-50 start-50 translate-middle text-warning">
              Have a Nice Trip with FlyProView
            </h4>
          </div>

          {!loader ? (
            <>
              <div className="row align-items-center p-3">
                <h6 className="fw-normal">
                  Booking # {ticket?.associatedRecords?.[0]?.reference}
                </h6>
                <div className="col-md-12 my-3">
                  <h6 className="text-dark fw-bold">Flight Routes</h6>
                  {departureRoute?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex mb-3 gap-2 align-items-center justify-content-start"
                    >
                      <h6 className="m-0 ">
                        {getCityDataName(item?.departure?.iataCode)}
                      </h6>
                      <span className="text-black"> TO </span>{" "}
                      <h6 className="m-0 ">
                        {getCityDataName(item?.arrival?.iataCode)}
                      </h6>
                      <span className="text-black"> on </span>{" "}
                      {moment(item?.departure?.at).format(
                        "hh:mm a MMM DD, YYYY"
                      )}
                    </div>
                  ))}

                  {returnRoute?.length > 0 && (
                    <>
                      <h6 className="text-dark fw-bold">Return Flight</h6>
                      {returnRoute?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex mb-3 gap-2 align-items-center justify-content-start"
                        >
                          <h6 className="m-0 ">
                            {getCityDataName(item?.departure?.iataCode)}
                          </h6>
                          <span className="text-black"> TO </span>{" "}
                          <h6 className="m-0 ">
                            {getCityDataName(item?.arrival?.iataCode)}
                          </h6>
                          <span className="text-black"> on </span>{" "}
                          {moment(item?.departure?.at).format(
                            "hh:mm a MMM DD, YYYY"
                          )}
                        </div>
                      ))}
                    </>
                  )}
                  <div className="row">
                    <h6 className="text-dark fw-bold mt-3">Travelers</h6>
                    {ticket?.travelers?.map((traveler, index) => {
                      return (
                        <>
                          <div className="col-6 my-2 mt-1" key={index}>
                            <p className="text-white m-0 text-nowrap">
                              First Name
                            </p>
                            <h6 className="text-white fw-normal m-0">
                              {traveler?.name?.firstName}
                            </h6>
                          </div>
                          <div className="col-6 my-2" key={index + 5}>
                            <p className="text-white m-0">Ticket #</p>
                            <h6 className="text-white fw-normal m-0 text-nowrap">
                              {ticket?.tickets[index]?.documentNumber}
                            </h6>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex py-5 justify-content-center align-items-center">
                <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#FFC107"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  speed={10.5}
                />
              </div>
              <h5 className="text-warning text-center my-4">
                Please wait we are issuing your ticket!
              </h5>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketModal;
