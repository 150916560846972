import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apis from "services";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

const UpdatePasswordComponent = () => {
  const { user } = useSelector((store) => store.user);
  const [formData, setFormData] = useState({ email: "", password: "" ,confirmPassword:""});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { state } = useLocation();
  const onChangeHandler = (e) => {
    let { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  useEffect(() => {
    if (user) {
      navigate("/flight_search");
    }
  }, []);

  useEffect(() => {
    if (state?.email) {
      setFormData((prevState) => ({ ...prevState, email: state?.email }));
    } else {
      navigate("/login");
    }
  }, []);
// update passowrd functionality
  const submitHandler = async (e) => {
    e.preventDefault();
    
    if(formData?.password !== formData?.confirmPassword)
      {
        return toast.error("Password and confirm password does not match")
      }
      else{
        try {
          setLoading(true);
          const {confirmPassword , ...restData} = formData;
          const response = await apis.authResetPassword(restData);
          if (response?.data?.status) {
              toast.success(response?.data?.message);
              setTimeout(() => {
                navigate("/login");
              }, 2000);
            }
            setLoading(false);
          }
         catch (e) {
          setLoading(false);
          console.log(e);
          toast.error(e?.response?.data?.message);
        }
      }
  };


  return (
<div class="container-fluid p-0 bg-otp d-flex align-items-center ">
      <div class="container rounded-4 contents">
        <div class="row align-items-center my-3">
          <div class="mx-auto col-md-6 col-lg-4 my-3">
          <form onSubmit={submitHandler}>
            <div class="row bg-white p-3 py-4 rounded-3 ">
              <h1 class="fw-bold text-center">Update Password</h1>
              <h6 class="fw-normal text-secondary text-center">
                Enter a new password for {state.email}
              </h6>
              <div class="col-12 mt-3 p-0">
                <p class="m-0 text-secondary">Password</p>
                <input
                  type="password"
                  minLength={8}
                  id="password"
                  required
                  onChange={onChangeHandler}
                  class="hotel_form_input px-2"
                  placeholder="********"
                />
              </div>
              <div class="col-12 mt-3 p-0">
                <p class="m-0 text-secondary">Confirm Password</p>
                <input
                  type="password"
                  minLength={8}
                  id="confirmPassword"
                  required
                  onChange={onChangeHandler}
                  class="hotel_form_input px-2"
                  placeholder="********"
                />
              </div>

              
              <button disabled={loading} type="submit" class="btn btn-purple w-100 mt-3 py-2 d-flex align-items-center justify-content-center">
                 {loading ? <> 
                 
                 {/* <Spinner animation="border" variant="light" size="sm" /> Updating Password */}
                 <ThreeDots
  visible={true}
  height="25"
  width="70"
  color="#fff"
  radius="5"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
                 
                 </> : "Update Password"}
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default UpdatePasswordComponent;
