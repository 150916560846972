import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  validateCancellation,
  validateVerification,
} from "validations/validateContactus";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";
import apis from "services";
import { ThreeDots } from "react-loader-spinner";
import { errorValidate } from "validations/errorHandleJoi";

const ContactUsForm = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("cancellation/rebooking");
  const [verification, setVerification] = useState({
    fullName: "",
    departureDate: "",
    departureCity: "",
    arrivalCity: "",
    phone: "",
    email: "",
    message: "",
  });
  const [calcellation, setCancellation] = useState({
    bookingNumber: "",
    email: "",
    message: "",
  });
  // query type selection and getting data in state
  const onChange = (e) => {
    const { name, value } = e.target;
    if (title === "cancellation/rebooking") {
      setCancellation((prev) => ({ ...prev, [name]: value }));
    } else if (title === "email_verification") {
      setVerification((prev) => ({ ...prev, [name]: value }));
    }
  };
  //  calling api to send data
  const handleSubmit = async () => {
    try {
      let data = {
        title: title,
      };
      if (
        title === "cancellation/rebooking" ||
        title === "general_discussion"
      ) {
        data = { ...data, ...calcellation };
      } else {
        data = { ...data, ...verification };
      }
      setLoading(true);
      const response = await apis.sendContactusQuery(data);
      toast.success(response?.data?.message);
      setLoading(false);
      setVerification({
        fullName: "",
        departureDate: "",
        departureCity: "",
        arrivalCity: "",
        phone: "",
        email: "",
        message: "",
      });
      setCancellation({
        bookingNumber: "",
        email: "",
        message: "",
      });
      setTitle("cancellation/rebooking");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // send query in case of user needs help / information
  const sendQuery = (e) => {
    e.preventDefault();
    let response;
    if (title === "cancellation/rebooking" || title === "general_discussion") {
      response = errorValidate(validateCancellation(calcellation));
      setError(null);
    } else if (title === "email_verification") {
      response = errorValidate(validateVerification(verification));
      setError(null);
    }
    if (response === true) {
      setError(null);
      handleSubmit();
    } else {
      setError(response);
    }
  };

  return (
    <div className="container my-5">
      <h1 className="text-center fw-bolder text-gold mt-5 headings text-uppercase ">
        {state?.cancellation ? "cancellation" : "CONTACT US"}
      </h1>
      <p className="text-center text-gold text-success-emphasis  m-0 h6 mb-4">
        We Will Contact You Shortly!
      </p>
      <form onSubmit={sendQuery}>
        <div className="row d-flex flex-column align-items-center justify-content-center">
          <div className="col-lg-8 bg_gold rounded-3 shadow p-3 p-md-4">
            <div className="row">
              <div className="col-12 mb-2">
                <label className="ms-2 fs-6 text-white">Title</label>
                <select
                  name="title"
                  className="contact-form-input border-0 "
                  onChange={(e) => setTitle(e.target.value)}
                >
                  <option value="cancellation/rebooking">
                    Cancellation / Rebooking
                  </option>
                  <option value="email_verification">Email Verification</option>
                  <option value="general_discussion">General Discussion</option>
                </select>
              </div>
              {(title === "cancellation/rebooking" ||
                title === "general_discussion") && (
                <>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">
                      Booking Number
                    </label>
                    <input
                      type="text"
                      name="bookingNumber"
                      value={calcellation?.bookingNumber}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.bookingNumber && (
                      <p className="text-danger m-0 mt-1">
                        {error?.bookingNumber}
                      </p>
                    )}
                  </div>

                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">Email</label>
                    <input
                      type="text"
                      name="email"
                      value={calcellation?.email}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.email && (
                      <p className="text-danger m-0 mt-1">{error?.email}</p>
                    )}
                  </div>

                  <div className="col-lg-12 mt-2">
                    <label htmlFor="Message" className="ms-2 text-white fs-6 ">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={calcellation?.message}
                      onChange={onChange}
                      rows="5"
                      className="contact-form-input text-area-contact rounded-2 resize-none border-0"
                    ></textarea>
                    {error?.message && (
                      <p className="text-danger m-0 mt-1">{error?.message}</p>
                    )}
                  </div>
                </>
              )}

              {title === "email_verification" && (
                <>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">Full Name</label>
                    <input
                      type="text"
                      name="fullName"
                      required
                      value={verification?.fullName}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.fullName && (
                      <p className="text-danger m-0 mt-1">{error?.fullName}</p>
                    )}
                  </div>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">Email</label>
                    <input
                      type="email"
                      required
                      name="email"
                      value={verification?.email}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.email && (
                      <p className="text-danger m-0 mt-1">{error?.email}</p>
                    )}
                  </div>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">Phone</label>

                    <PhoneInput
                      value={verification?.phone}
                      country={"se"}
                      className="contact-form-input border-0 w-100 px-0 "
                      onChange={(phone) =>
                        setVerification({ ...verification, phone })
                      }
                    />
                    {error?.phone && (
                      <p className="text-danger m-0 mt-1">{error?.phone}</p>
                    )}
                  </div>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">
                      Departure Date
                    </label>

                    <input
                      type="date"
                      name="departureDate"
                      required
                      onChange={onChange}
                      min={new Date().toISOString().split("T")[0]}
                      className="contact-form-input border-0 "
                    />
                  </div>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">
                      Departure City
                    </label>
                    <input
                      type="text"
                      required
                      id="departureCity"
                      name="departureCity"
                      value={verification?.departureCity}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.departureCity && (
                      <p className="text-danger m-0 mt-1">
                        {error?.departureCity}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 my-1">
                    <label className="ms-2 fs-6 text-white">Arrival City</label>
                    <input
                      type="text"
                      name="arrivalCity"
                      required
                      value={verification?.arrivalCity}
                      onChange={onChange}
                      className="contact-form-input border-0 "
                    />
                    {error?.arrivalCity && (
                      <p className="text-danger m-0 mt-1">
                        {error?.arrivalCity}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-12 mt-2">
                    <label htmlFor="Message" className="ms-2 text-white fs-6 ">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={calcellation?.message}
                      onChange={onChange}
                      rows="5"
                      className="contact-form-input text-area-contact rounded-2 resize-none border-0"
                    ></textarea>
                    {error?.message && (
                      <p className="text-danger m-0 mt-1">{error?.message}</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <button
              className="btn gradient_btn w-100 mt-3 d-flex justify-content-center align-items-center"
              type="submit"
            >
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="25"
                  width="70"
                  color="#000"
                  radius="5"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
