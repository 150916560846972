import React, { useEffect, useState } from "react";
import FlightSearchForm from "components/FlightSearchForm";
// import Navbars from "components/Navbars";
import Layout from "../components/Layout";
import MiddleContent from "components/MiddleContent";
import FlightSearchResult from "components/FlightSearchResult";
import Loader from "components/Loader";
import FlightFilters from "components/FlightFilters";
// import { ArrowForward } from "@material-ui/icons";
import { Helmet } from "react-helmet";

import ReactPaginate from "react-paginate";

const SearchFlight = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [othersData, setOthersData] = useState([]);
  const [inputValueFrom, setInputValueFrom] = useState("");
  const [inputValueTo, setInputValueTo] = useState("");
  const [searchStart, setSearchStart] = useState(false);
  const cachedData = JSON.parse(sessionStorage.getItem("data"));
  const cachedOthersData = JSON.parse(sessionStorage.getItem("othersData"));
  const fromData = sessionStorage.getItem("from");
  const toData = sessionStorage.getItem("to");
  const [minimumPrice, setMinimumPrice] = useState(0);

  // console.log(toData,"toDatatoDatatoDatatoData")

  const [priceMinimumANdMaximum, setPriceMinimumANdMaximum] = useState({
    miPrice: 0,
    maxPrice: 0,
  });
  const [priceRange, setPriceRange] = useState([0, 100000]); // Example price range
  const [selectedStops, setSelectedStops] = useState("All"); // Filter for number of stops
  const [currency, setCurrency] = useState("SEK");
  const [ip, setIP] = useState("0.0.0.0");

  function generateRandomIPv4() {
    const randomOctet = () => Math.floor(Math.random() * 256);
    return `${randomOctet()}.${randomOctet()}.${randomOctet()}.${randomOctet()}`;
  }

  useEffect(() => {
    if (cachedData && cachedOthersData && fromData && toData) {
      // setData(cachedData);
      setData(cachedData);
      setOthersData(cachedOthersData);
      setInputValueFrom(fromData);
      setInputValueTo(toData);
    }
    setIP(generateRandomIPv4());
  }, []);

  // useEffect(() => {
  //   const cleanup = () => {
  //     sessionStorage.removeItem("data");
  //     sessionStorage.removeItem("othersData");
  //     sessionStorage.removeItem("from");
  //     sessionStorage.removeItem("to");
  //   };
  //   return cleanup;
  // }, []);

  // console.log(data, "DATA");

  useEffect(() => {
    if (cachedData?.length > 0) {
      let { minPrice, maxPrice } = findMinAndMaxFlightPrices(cachedData);
      setPriceMinimumANdMaximum({ minPrice: minPrice, maxPrice: maxPrice });
      setPriceRange([minPrice, maxPrice]);
      setMinimumPrice(minPrice);
      filterFlights([minPrice, maxPrice], false);
    }
  }, [cachedData?.length]);

  // useEffect(()=>{
  //   if(fromData && toData)
  //     {
  //       setInputValueFrom(fromData);

  //       setInputValueTo(toData);
  //     }
  // },[fromData,toData])

  function findMinAndMaxFlightPrices(flightData) {
    if (!flightData || flightData.length === 0) {
      return { minPrice: null, maxPrice: null };
    }

    let minPrice = Number.MAX_VALUE;
    let maxPrice = Number.MIN_VALUE;

    if (flightData.length > 0) {
      flightData.forEach((item) => {
        if (item.price && item.price.grandTotal) {
          const price = parseFloat(item.price.grandTotal);
          if (!isNaN(price)) {
            if (price <= minPrice) {
              minPrice = price;
            }
            if (price >= maxPrice) {
              maxPrice = price;
            }
          }
        }
      });

      setMinimumPrice(minPrice);
      return { minPrice, maxPrice };
    }
  }
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10; // Number of records to display per page

  const [pageShow, setPageShow] = useState(10);

  const [filterLoading, setFilterLoading] = useState(false);
  // ...

  // Calculate the total number of pages based on the perPage value
  const pageCount = Math.ceil(data?.length / pageShow);

  // const filterFlights = (newPriceRange) => {
  //   const [minPrice, maxPrice] = newPriceRange;

  //   // Filter the flights based on the price range
  //   const filteredFlights = cachedData?.filter((flight) => {
  //     const price = parseFloat(flight.price.grandTotal);
  //     return price >= minPrice && price <= maxPrice;
  //   });

  //   setData(filteredFlights); // Update the filtered flight data state
  //   if(filteredFlights?.length >= 10)
  //   {
  //     setPageShow(10)
  //   }
  //  else{
  //   setPageShow(filteredFlights?.length)
  //  }
  // };

  const filterFlights = (newPriceRange, stop) => {
    setFilterLoading(true);
    const [minPrice, maxPrice] = newPriceRange;

    // Filter the flights based on the price range and selected flight type
    const filteredFlights = cachedData?.filter((flight) => {
      const price = parseFloat(flight.price.grandTotal);
      const stopsCount = flight.itineraries[0].segments.length - 1;

      // Check if the flight's price is within the selected price range
      const isWithinPriceRange = price >= minPrice && price <= maxPrice;

      // Check the selected flight type and filter accordingly
      if (!stop) {
        if (selectedStops === "Directed") {
          return isWithinPriceRange && stopsCount === 0; // Direct flights have 0 stops
        } else if (selectedStops === "Connected") {
          return isWithinPriceRange && stopsCount > 0; // Connected flights have more than 0 stops
        } else {
          return isWithinPriceRange; // "All" flights include both direct and connected
        }
      } else {
        return isWithinPriceRange;
      }
    });

    setData(filteredFlights); // Update the filtered flight data state
    // setFilterLoading(false);
    setTimeout(() => {
      setFilterLoading(false);
    }, 2000);
  };

  useEffect(() => {
    // filterFlights(priceRange)
  }, [selectedStops, cachedData?.length]);

  const handlePriceRangeChange = (newPriceRange) => {
    setPriceRange(newPriceRange); // Update the price range state
    // filterFlights(newPriceRange); // Filter flights based on the new price range
  };

  // Function to calculate paginated data
  const calculatePaginatedData = () => {
    let paginatedData = [];

    if (data?.length > 10) {
      paginatedData = data?.slice(
        currentPage * perPage,
        (currentPage + 1) * perPage
      );
    } else {
      paginatedData = data;
    }

    return paginatedData;
  };

  // ...

  // Use slice to paginate your data based on the currentPage and perPage
  const paginatedData = calculatePaginatedData();

  useEffect(() => {
    if (searchStart || loading) {
      const resultSection = document.getElementById("search-result-detail");
      if (resultSection) {
        resultSection.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [searchStart, loading]);

  // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    const resultSection = document.getElementById("search-result-detail");
    if (resultSection) {
      resultSection.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  const [addClass, setAddClass] = useState(true);

  function findCheapestProduct(products) {
    if (products?.length === 0) {
      // Handle the case where the array is empty
      return { index: -1, isCheapest: false };
    }

    // console.log(products?.length, "i-----------------");

    let cheapestIndex = 0;

    for (let i = 1; i < products?.length; i++) {
      if (
        products[i]?.price?.grandTotal <
        products[cheapestIndex]?.price?.grandTotal
      ) {
        cheapestIndex = i;
      }
    }

    // return { index: cheapestIndex, isCheapest: true };
  }

  findCheapestProduct(data);

  // console.log(data,"datadatadatadatadata")
  const [minDuration, setMinDuration] = useState(null);

  useEffect(() => {
    // Function to convert duration string to milliseconds
    const durationToMilliseconds = (durationString) => {
      const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
      const matches = durationString.match(regex);
      let hours = matches[1] ? parseInt(matches[1], 10) : 0;
      let minutes = matches[2] ? parseInt(matches[2], 10) : 0;
      return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    };

    // Find minimum duration in milliseconds
    let minMilliseconds = Infinity,
      duration;
    data?.forEach((offer) => {
      const durationMs = durationToMilliseconds(
        offer?.itineraries[0]?.duration
      );
      if (durationMs < minMilliseconds) {
        duration = offer?.itineraries[0]?.duration;

        minMilliseconds = durationMs;
      }
    });

    // Convert minimum duration back to string format
    if (duration) {
      setMinDuration(duration);
    }
  }, [data]);

  // console.log(minDuration,"minDurationminDuration")
  return (
    <Layout>
      <Helmet>
        <title>Flight Search</title>
      </Helmet>
      <div className="flight__banner">
        {/* <Navbars /> */}
        <FlightSearchForm
          setSearchStart={setSearchStart}
          setOthersData={setOthersData}
          setInputValueFrom={setInputValueFrom}
          setInputValueTo={setInputValueTo}
          inputValueTo={inputValueTo}
          inputValueFrom={inputValueFrom}
          setLoading={setLoading}
          loading={loading}
          setData={setData}
          setSelectedStops={setSelectedStops}
          selectedStops={selectedStops}
          ip={ip}
          currency={currency}
        />
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <>
          <div id="result_section">
            <div className="result_section-left">
              {cachedData?.length > 0 && inputValueFrom && inputValueTo && (
                <div className="result_section-left-left">
                  <div className="result_section-left-wrap">
                    <FlightFilters
                      priceRange={priceRange}
                      setPriceRange={handlePriceRangeChange}
                      priceMinimumANdMaximum={priceMinimumANdMaximum}
                      data={data}
                      setSelectedStops={setSelectedStops}
                      selectedStops={selectedStops}
                      addClass={addClass}
                      setAddClass={setAddClass}
                      filterFlights={filterFlights}
                      currency={currency}
                    />
                  </div>
                  <div
                    className={`button-filter ${
                      addClass ? "hide-filter-mobile" : ""
                    }`}
                  >
                    <button onClick={() => filterFlights(priceRange, false)}>
                      {filterLoading ? "Loading" : "Apply"}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="result_section-right">
              <div className="result_section-right-wrap">
                <div className="result_section-right-one">
                  {cachedData?.length > 0 && inputValueFrom && inputValueTo && (
                    <din
                      className="search-result-detail"
                      id="search-result-detail"
                    >
                      <div className="left">
                        <p>{data?.length} Flight Founds</p>
                      </div>
                      <div className="right">
                        <ul>
                          <li>{inputValueFrom}</li>
                          <li>
                            <svg
                              width="31"
                              height="31"
                              viewBox="0 0 31 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.2994 28.0938H9.68761C9.52566 28.0937 9.36631 28.053 9.22413 27.9755C9.08195 27.898 8.96147 27.786 8.87372 27.6499C8.78597 27.5138 8.73374 27.3579 8.72181 27.1964C8.70989 27.0348 8.73864 26.8729 8.80544 26.7254L12.7083 18.1126L6.84796 17.9824L4.71066 20.572C4.30318 21.0842 3.97804 21.3125 3.14855 21.3125H2.06355C1.89176 21.318 1.72117 21.2821 1.56623 21.2077C1.4113 21.1332 1.27659 21.0226 1.17351 20.885C1.02941 20.6907 0.887727 20.3613 1.02577 19.8915L2.22581 15.5926C2.2349 15.5605 2.24579 15.5285 2.2579 15.497C2.25851 15.494 2.25851 15.4909 2.2579 15.4879C2.2454 15.4564 2.23469 15.4243 2.22581 15.3916L1.02456 11.0655C0.894388 10.6048 1.03667 10.2827 1.17956 10.0932C1.27552 9.96584 1.4 9.86282 1.54302 9.79236C1.68603 9.7219 1.84358 9.68599 2.003 9.6875H3.14855C3.76794 9.6875 4.36917 9.96541 4.72277 10.4141L6.81587 12.9601L12.7083 12.8729L8.80665 4.27521C8.73976 4.12775 8.71089 3.96588 8.7227 3.80438C8.7345 3.64289 8.7866 3.48693 8.87422 3.35076C8.96185 3.21459 9.08221 3.10256 9.2243 3.02491C9.36639 2.94726 9.52568 2.90646 9.68761 2.90625H11.3169C11.5442 2.91082 11.7676 2.96653 11.9704 3.06925C12.1732 3.17198 12.3503 3.31907 12.4885 3.49961L20.0599 12.7027L23.5577 12.6107C23.8138 12.5968 24.5234 12.5919 24.6875 12.5919C28.0333 12.5938 30.0314 13.68 30.0314 15.5C30.0314 16.0728 29.8025 17.1348 28.2713 17.8105C27.3673 18.2101 26.1612 18.4123 24.6863 18.4123C24.524 18.4123 23.8162 18.4075 23.5565 18.3935L20.0593 18.3003L12.4691 27.5034C12.3308 27.6832 12.1539 27.8295 11.9514 27.9317C11.749 28.0339 11.5261 28.0893 11.2994 28.0938Z"
                                fill="#C3A465"
                              />
                            </svg>
                          </li>
                          <li>{inputValueTo}</li>
                        </ul>
                      </div>
                    </din>
                    // <div id="result_filter_section" className="container mt-5">
                    //   <div className="col-lg-9 ms-lg-auto">
                    //     <div className="d-flex justify-content-between">
                    //       <h3 className="mb-4">{data?.length} Flight Founds</h3>
                    //       <h3 className="mb-4">
                    //         {inputValueFrom} <ArrowForward /> {inputValueTo}
                    //       </h3>
                    //     </div>
                    //   </div>
                    // </div>
                  )}
                </div>
                <div className="result_section-right-two">
                  {paginatedData?.length > 0 ? (
                    paginatedData?.map((item, index) => {
                      return (
                        <>
                          <FlightSearchResult
                            minimumPrice={minimumPrice || 0}
                            index={index}
                            from={inputValueFrom}
                            to={inputValueTo}
                            item={item}
                            othersData={othersData}
                            minDuration={minDuration}
                            currency={currency}
                            ip={ip}
                          />
                        </>
                      );
                    })
                  ) : (
                    <>
                      {searchStart && (
                        <div className="d-flex justify-content-center">
                          No record found
                        </div>
                      )}
                    </>
                  )}
                  {data?.length >= 10 && (
                    <>
                      <div className="d-flex justify-content-center mt-3">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          onPageChange={handlePageChange}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={10}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <MiddleContent />
    </Layout>
  );
};

export default SearchFlight;
