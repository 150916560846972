import React, { useState, useEffect } from "react";
import userImage from "./assets/profileImage.png";
import { useDispatch, useSelector } from "react-redux";
import apis from "services";
import { toast } from "react-hot-toast";
import { setUser } from "redux/slices/userSlice";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const UserProfile = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    phone: "",
    email: "",
    image: "",
  });
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.user);
  useEffect(() => {
    if (user) {
      const { _id, isVerified, ...restData } = user;
      setFormData(restData);
    } else {
      navigate("/login");
    }
  }, []);

  // set data in formdata
  const onChangeHandler = (e) => {
    let { id, value, files } = e.target;
    if (files) {
      setImage(URL.createObjectURL(files[0]));
      setFormData((prevState) => ({ ...prevState, image: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [id]: value }));
    }
  };
// updating users profile information
  const submitHandler = async (e) => {
    e.preventDefault();
    const form_data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      console.log(key, value);
      form_data.append(key, value);
    }
    try {
      setLoading(true);

      const response = await apis.updateProfile(user?._id, form_data);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        dispatch(setUser(response?.data?.user));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e?.response?.data?.message, { id: 1 });
    }
  };

  return (
    <div className="container mt-5 mb-3">
      <h1>User Profile</h1>
      <img
        src={image ? image : user?.image ? user?.image : userImage}
        className="img=fluid userProfileImage"
        alt=""
      />
      {/* <p className="text-purple text-center text-decoration-underline">
        Change Profile Image
      </p> */}
      <div className="row d-flex flex-column align-items-center mt-2 justify-content-center">
        <form onSubmit={submitHandler} className="d-flex flex-column align-items-center">
          {/* <h2 className="text-center mb-4">Update Information</h2> */}
          <div className="col-md-8 col-lg-5">
            <div className="bg_gold rounded-3 p-3 p-md-4">
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label ms-2 text-white"
                >
                  Change Profile Image
                </label>

                <input
                  type="file"
                  class="contact-form-input bg-white border-0 "
                  id="image"
                  onChange={onChangeHandler}
                />
              </div>

              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label ms-2 text-white"
                >
                  Email address
                </label>
                <input
                  type="email"
                  class="contact-form-input border-0 "
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={onChangeHandler}
                  required
                  readOnly
                  value={formData?.email}
                />
                <div id="emailHelp" class="form-text ms-2 text-white">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div class="mb-3">
                <label for="first Name" class="form-label ms-2 text-white">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  class="contact-form-input border-0 "
                  id="firstName"
                  onChange={onChangeHandler}
                  required
                  value={formData?.firstName}
                />
              </div>
              <div class="mb-3">
                <label for="last name" class="form-label ms-2 text-white">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  class="contact-form-input border-0 "
                  id="lastName"
                  onChange={onChangeHandler}
                  required
                  value={formData?.lastName}
                />
              </div>
              <div class="mb-3">
                <label for="age" class="form-label ms-2 text-white">
                  Age
                </label>
                <input
                  type="text"
                  placeholder="Age"
                  class="contact-form-input border-0 "
                  id="age"
                  onChange={onChangeHandler}
                  required
                  value={formData?.age}
                />
              </div>
              <div class="mb-3">
                <label for="age" class="form-label ms-2 text-white">
                  Phone Number
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  class="contact-form-input border-0 "
                  id="phone"
                  onChange={onChangeHandler}
                  required
                  value={formData?.phone}
                />
              </div>
            </div>
          </div>
          <div className="col-11 col-md-7 col-lg-4 mt-3">
            <button
              disabled={loading}
              type="submit"
              class="btn gradient_btn d-block mx-auto py-2 d-flex align-items-center justify-content-center w-100"
            >
              {loading ? (
                <>
                  {" "}
                  {/* <Spinner animation="border" variant="light" size="sm" />{" "}
                  Updating Profile */}
                  <ThreeDots
  visible={true}
  height="25"
  width="70"
  color="#fff"
  radius="5"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
                </>
              ) : (
                "Update Profile Information"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
