import UpdatePasswordComponent from 'components/UpdatePasswordComponent'
import React from 'react'
import { Helmet } from 'react-helmet'

const UpdatePassword = () => {
  return (
    <>
    <Helmet>
        <title>Update Password</title>
      </Helmet>
    <UpdatePasswordComponent/>      
    </>
  )
}

export default UpdatePassword
