import React from "react";
import Seat from "./Seat";
import styled from "styled-components";

// const StyledDeckContainer = styled.div`
//   width: ${(props) => `${props.width * 2.2}em`};
//   height: ${(props) => `${props.length * 2.1}em`};
// `;

const Deck = (props) => {
  const width = props.deck.deckConfiguration.width;
  const length = props.deck.deckConfiguration.length;
  const seatList = props.deck.seats;
  const exitRows = props.deck.deckConfiguration.exitRowsX;

  const taxService = props.seatTaxFlyProView;

  const handleSeatSelect = (seatNumber) => {
    props.setSelectedSeat(seatNumber);
    const seatData = seatList.find((seat) => seat.number === seatNumber);
    props.setSelectedSeatData(seatData);
  };

  const displaySeats = (seatList, onSeatSelect, selectedSeat) => {
    return (
      <div className="first">
      <div className="first-wrap">
        {seatList.map((seat) => (
          <Seat
            taxService={taxService}
            key={seat.number}
            number={seat.number}
            x={seat.coordinates.x}
            y={seat.coordinates.y}
            availability={seat.travelerPricing[0].seatAvailabilityStatus}
            price={seat.travelerPricing[0].price}
            selectedSeat={selectedSeat}
            onSeatSelect={onSeatSelect}
          />
        ))}
      </div>
      </div>
    );
  };

  return (
    <>
      {/* <div id="deck" style={{ width: `${width * 2.2}em`, height: `${length * 2.1}em` }}>
        {displaySeats(seatList, handleSeatSelect, props.selectedSeat)}
      </div> */}

      {seatList?.length > 0 ? (
        <div id="deck">
        <div id="deck-wrap">
          {displaySeats(seatList, handleSeatSelect, props.selectedSeat)}
        </div>
        </div>
      ) : (
        <div>Not avilable deck</div>
      )}
    </>
  );
};

export default Deck;
