import ForgotPasswordComp from 'components/ForgotPasswordComp'
import React from 'react'
import { Helmet } from 'react-helmet'

const ForgotPassword = () => {
  return (
    <>
    <Helmet>
        <title>Forgot Password</title>
      </Helmet>
    <ForgotPasswordComp />
    </>
  )
}

export default ForgotPassword