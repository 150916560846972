import Layout from "components/Layout";
import TicketModal from "components/Modal/TicketModal";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import apis from "services";
import { useSearchParams, useNavigate } from "react-router-dom";

const Ticket = () => {
  const [showTicketModal, setShowTicketModal] = useState(true);
  const [ticketData, setTicektData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const getDetails = async () => {
    sessionStorage.clear();
    try {
      setLoader(true);
      const res = await apis.getBookingDetails(searchParams.get("id"));
      if (res?.data?.booking) {
        setTicektData(res.data.booking);
      }
      console.log(res.data.booking, "res");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      navigate("/");
    }
  };
  useEffect(() => {
    if (searchParams.get("id")) {
      getDetails();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Ticket</title>
      </Helmet>
      {/* <Layout> */}
      <div className="container-fluid ticketBg"></div>
      <TicketModal
        loader={loader}
        ticketData={ticketData}
        showTicketModal={showTicketModal}
        setShowTicketModal={setShowTicketModal}
      />
      {/* </Layout> */}
    </>
  );
};

export default Ticket;
