// import React, { useState } from "react";
// import { FaCircleCheck,FaRegCircle } from "react-icons/fa6";
// import { GiRollingSuitcase } from "react-icons/gi";
// const ExtraServices = ({ data, setBagData,bagSelectHandler}) => {
//   const [selectedBaggage, setSelectedBaggage] = useState('');
 
//   console.log(data, "datadata");

//   const handleBaggageSelection = (baggageOption,index) => {
//     // setSelectedBaggage(prevSelected => prevSelected === index ? '' : index);
//     setSelectedBaggage(index);
//     setBagData({quantity : baggageOption.quantity , price : baggageOption?.price})
//     bagSelectHandler(baggageOption.quantity);
//   };


//   console.log(selectedBaggage,"selectedBaggage")
//   return (
//     <div className="container">
//       <h3>Extra Services</h3>
//       <div className="row">
//         <div className="col-lg-4 border-1-px rounded-2 shadow">
//           <h4 className="text-center mt-3">Baggage</h4>
//           {Object.values(data).map((baggageOption, index) => (
//             <div
//               key={index}
//               className={`d-flex px-md-4 mt-3 p-4 align-items-center justify-content-between`}
//               onClick={() => handleBaggageSelection(baggageOption,index)}
//             >
//               <FaCircleCheck className={`fs-4 text-purple ${selectedBaggage === index ? "" : "checkLight"}`} />
//               <p className="h6 m-0">{`${baggageOption.quantity} Quantity Extra`}</p>
//               <p className="h6 m-0 text-purple">
//                 {`${baggageOption.price.amount} ${baggageOption.price.currencyCode}`}
//               </p>
//             </div>
//           ))}
//         </div> 
//       </div>
//     </div>
//   );
// };

// export default ExtraServices;

import React ,{useState} from "react";
import { FaCircleCheck,FaRegCircle } from "react-icons/fa6";
import { GiRollingSuitcase } from "react-icons/gi";
const ExtraServices = ({ data, setBagData,bagSelectHandler}) => {
  
    const [selectedBaggage, setSelectedBaggage] = useState('');
  const handleBaggageSelection = (baggageOption,index) => {
    // setSelectedBaggage(prevSelected => prevSelected === index ? '' : index);
    setSelectedBaggage(index);
    setBagData({quantity : baggageOption.quantity , price : baggageOption?.price})
    bagSelectHandler(baggageOption.quantity);
  };
  
  return (
   
 
            <ul style={{gap:"20px",overflow:'auto'}}>
  
      {Object.values(data).map((baggageOption, index) => (
        
<>
              <li onClick={() => handleBaggageSelection(baggageOption,index)}>
                <div className="box" >
                  <svg width="51" height="52" className="bag" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.375 12.8259H27.625V8.57593H23.375V12.8259ZM25.5 25.5759C22.8083 25.5759 20.2406 25.0178 17.7969 23.9014C15.3531 22.7851 12.9625 21.537 10.625 20.1572V17.0759C10.625 15.9072 11.0415 14.907 11.8745 14.0754C12.7075 13.2438 13.7077 12.8273 14.875 12.8259H19.125V6.45093C19.125 5.84884 19.329 5.34451 19.737 4.93793C20.145 4.53134 20.6493 4.32734 21.25 4.32593H29.75C30.3521 4.32593 30.8571 4.52993 31.2651 4.93793C31.6731 5.34593 31.8764 5.85026 31.875 6.45093V12.8259H36.125C37.2938 12.8259 38.2946 13.2424 39.1276 14.0754C39.9606 14.9084 40.3764 15.9086 40.375 17.0759V20.1572C38.0375 21.5384 35.6469 22.7872 33.2031 23.9036C30.7594 25.0199 28.1917 25.5773 25.5 25.5759ZM14.875 46.8259V44.7009C13.7062 44.7009 12.7061 44.2851 11.8745 43.4536C11.0429 42.622 10.6264 41.6211 10.625 40.4509V24.9384C12.6083 26.1426 14.654 27.1697 16.762 28.0197C18.87 28.8697 21.0743 29.4363 23.375 29.7197V31.9509H27.625V29.7197C29.9271 29.4363 32.1321 28.8697 34.2401 28.0197C36.3481 27.1697 38.3931 26.1426 40.375 24.9384V40.4509C40.375 41.6197 39.9592 42.6206 39.1276 43.4536C38.296 44.2866 37.2952 44.7023 36.125 44.7009V46.8259H31.875V44.7009H19.125V46.8259H14.875Z" fill="black" />
                  </svg>
                  <p>{`${baggageOption.quantity} Package`}<br />SEK {`${baggageOption.price.amount}`}</p>
                  {/* <div className="check"> */}
                  {
             selectedBaggage === index ? 
            <FaCircleCheck className="check fs-3 border-0 " color="#C3A465"/>
            :
           <div className="check"> </div>
                   }
                  {/* </div> */}
                </div>
              </li>
  
        {/* <div
        key={index}
        onClick={() => handleBaggageSelection(baggageOption,index)}         
        className={`baggageCard m-2 rounded-2 shadow-sm d-flex flex-column justify-content-around ${selectedBaggage === index ? "selected__baggage" : "bg-lights"}`}
      
        >
          <GiRollingSuitcase className={`bagIcon d-block mx-auto ${selectedBaggage !== index ? "text-white" : "text-warning"}`}/>
          <div className="d-flex px-md-4 mt-3 align-items-center justify-content-between">
            {
             selectedBaggage === index ? 
            <FaCircleCheck className="fs-4 text-warning"/>
            :
            <FaRegCircle className="fs-4 text-dark" />
          }
            <p className={`h6 m-0  ${selectedBaggage !== index ? "text-dark" : "text-warning"}`}  >{`${baggageOption.quantity} Quantity`}</p>
            <p className={`h5 m-0  ${selectedBaggage !== index ? "text-dark" : "text-warning"}`} >€{`${baggageOption.price.amount}`}</p>
          </div>
        </div> */}
        </>
         ))}
         </ul>
          
  );
};

export default ExtraServices;