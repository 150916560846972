import React, { useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apis from "services";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

const ForgotPasswordComp = () => {

  const { user } = useSelector((store) => store.user);
 
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (user) {
      navigate("/flight_search");
    }
  }, []);



  const onChangeHandler = (e) => {
    let { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  // change passwords submit functionality
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apis.authForget(formData);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/otp_verification",{state:{email:formData?.email, type:"forgot"}});
        }, 2000);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e?.response?.data?.message,{id:1});
    }
  };





  return (
    <div className="container-fluid p-0 bg-otp d-flex align-items-center ">
      <div className="container rounded-4 contents">
        <div className="row align-items-center my-3">
          <div className="mx-auto col-md-6 col-lg-4 my-3">
            <form onSubmit={submitHandler}>
            <div className="row bg-white p-3 py-4 rounded-3 ">
              <h1 className="fw-bold text-center">Forgot Password</h1>
              <h6 className="fw-normal text-secondary text-center">
                Let's Verify for your vacation with loved ones
              </h6>
              <div className="col-12 mt-3 p-0">
                <p className="m-0 text-secondary">Enter Email</p>
                <input
                  type="email"
                  id="email"
                  onChange={onChangeHandler}
                  required
                  className="hotel_form_input px-2"
                  placeholder="Email"
                />
              </div>

              <p className="text-center text-secondary m-0 mt-3">
                You will get Email verification code 
              </p>
              
              <button disabled={loading} type="submit" className="btn btn-purple w-100 mt-3 py-2 d-flex justify-content-center align-items-center">
                 {loading ? <> 
                 
                 {/* <Spinner animation="border" variant="light" size="sm" /> Sending Email */}
                 <ThreeDots
  visible={true}
  height="25"
  width="70"
  color="#fff"
  radius="5"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
                 </> : "Send Email"}
              </button>
             
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComp;
