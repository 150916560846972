
import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Deck from "./DeckConf/Deck";
import apis from "services";
import { useNavigate } from "react-router-dom";

const SeatMap = ({
  seatTaxFlyProView,
  dataSelectHandler,
  state,
  seatMap,
  setSelectedSeat,
  setSelectedSeatData,
  selectedSeatData,
  selectedSeat,
  getCityName,
  setLoading,
  loading,
  handleTravelerChange,
  selectedRouteIndex,
  handleRouteChange,
  setSelectedRouteIndex,
  setSelectedTravelerIndex,
  selectedTravelerIndex,
  getSeatsForSelectedRoute,
}) => {
  console.log(getSeatsForSelectedRoute(),"getSeatsForSelectedRoute()")
  return (
    <div className="Traveler">
      <div className="select">
        <label htmlFor="travelerDropdown" className="m-0 form-label fw-semibold">
          Select Traveler
        </label>
        <div className="select-wrap">
          <select
            className="form-select"
            id="travelerDropdown"
            value={selectedTravelerIndex}
            onChange={handleTravelerChange}
          >
            {state?.travelerPricings?.map((traveler, index) => (
              <option value={index} key={index}>
                Traveler {index + 1} - {traveler.travelerType}
              </option>
            ))}
          </select>
          <svg width="30" height="30" className="select-icons" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3375 13.0124C17.0968 11.9515 17.5051 10.6796 17.5051 9.37494C17.5051 8.0703 17.0968 6.79836 16.3375 5.73744C17.0481 5.25239 17.8896 4.99515 18.75 4.99994C19.9103 4.99994 21.0231 5.46088 21.8436 6.28135C22.6641 7.10182 23.125 8.21462 23.125 9.37494C23.125 10.5353 22.6641 11.6481 21.8436 12.4685C21.0231 13.289 19.9103 13.7499 18.75 13.7499C17.8896 13.7547 17.0481 13.4975 16.3375 13.0124ZM6.875 9.37494C6.875 8.50965 7.13159 7.66379 7.61232 6.94432C8.09305 6.22486 8.77633 5.6641 9.57576 5.33297C10.3752 5.00184 11.2549 4.9152 12.1035 5.08401C12.9522 5.25282 13.7317 5.6695 14.3436 6.28135C14.9554 6.89321 15.3721 7.67276 15.5409 8.52142C15.7097 9.37009 15.6231 10.2498 15.292 11.0492C14.9608 11.8486 14.4001 12.5319 13.6806 13.0126C12.9612 13.4934 12.1153 13.7499 11.25 13.7499C10.0897 13.7499 8.97688 13.289 8.15641 12.4685C7.33594 11.6481 6.875 10.5353 6.875 9.37494ZM9.375 9.37494C9.375 9.74578 9.48497 10.1083 9.69099 10.4166C9.89702 10.725 10.1899 10.9653 10.5325 11.1072C10.8751 11.2491 11.2521 11.2863 11.6158 11.2139C11.9795 11.1416 12.3136 10.963 12.5758 10.7008C12.838 10.4385 13.0166 10.1045 13.089 9.74074C13.1613 9.37702 13.1242 9.00002 12.9823 8.65741C12.8404 8.3148 12.6 8.02197 12.2917 7.81594C11.9834 7.60991 11.6208 7.49994 11.25 7.49994C10.7527 7.49994 10.2758 7.69749 9.92417 8.04912C9.57254 8.40075 9.375 8.87766 9.375 9.37494ZM20 21.2499V23.7499H2.5V21.2499C2.5 21.2499 2.5 16.2499 11.25 16.2499C20 16.2499 20 21.2499 20 21.2499ZM17.5 21.2499C17.325 20.2749 15.8375 18.7499 11.25 18.7499C6.6625 18.7499 5.0875 20.3874 5 21.2499M19.9375 16.2499C20.7036 16.8459 21.33 17.6023 21.7727 18.466C22.2154 19.3298 22.4636 20.28 22.5 21.2499V23.7499H27.5V21.2499C27.5 21.2499 27.5 16.7124 19.925 16.2499H19.9375Z" fill="black" />
          </svg>
        </div>
      </div>
      <div className="select">
        <label htmlFor="routeDropdown" className="form-label m-0 fw-semibold text-center two">Select Route</label>
        <div className="select-wrap">
        <select className="form-select" id="routeDropdown" value={selectedRouteIndex} onChange={handleRouteChange} >
          {state?.itineraries?.[0]?.segments?.map((dataItem, index) => (
            <option value={index} key={index}>
              {getCityName(dataItem?.departure?.iataCode)} to {getCityName(dataItem?.arrival?.iataCode)}
            </option>
          ))}
        </select>
        <svg width="28" height="28" className="select-icons" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 24.5C9.21667 24.5 8.11806 24.0431 7.20417 23.1292C6.29028 22.2153 5.83333 21.1167 5.83333 19.8333V10.2958C5.15278 10.0431 4.59394 9.62033 4.15683 9.02767C3.71972 8.435 3.50078 7.75911 3.5 7C3.5 6.02778 3.84028 5.20139 4.52083 4.52083C5.20139 3.84028 6.02778 3.5 7 3.5C7.97222 3.5 8.79861 3.84028 9.47917 4.52083C10.1597 5.20139 10.5 6.02778 10.5 7C10.5 7.75833 10.2814 8.43422 9.84433 9.02767C9.40722 9.62111 8.848 10.0438 8.16667 10.2958V19.8333C8.16667 20.475 8.39533 21.0245 8.85267 21.4818C9.31 21.9392 9.85911 22.1674 10.5 22.1667C11.1417 22.1667 11.6912 21.9384 12.1485 21.4818C12.6058 21.0253 12.8341 20.4758 12.8333 19.8333V8.16667C12.8333 6.88333 13.2903 5.78472 14.2042 4.87083C15.1181 3.95694 16.2167 3.5 17.5 3.5C18.7833 3.5 19.8819 3.95694 20.7958 4.87083C21.7097 5.78472 22.1667 6.88333 22.1667 8.16667V17.7042C22.8472 17.9569 23.4064 18.3801 23.8443 18.9735C24.2822 19.5669 24.5008 20.2424 24.5 21C24.5 21.9722 24.1597 22.7986 23.4792 23.4792C22.7986 24.1597 21.9722 24.5 21 24.5C20.0278 24.5 19.2014 24.1597 18.5208 23.4792C17.8403 22.7986 17.5 21.9722 17.5 21C17.5 20.2417 17.7189 19.5611 18.1568 18.9583C18.5947 18.3556 19.1536 17.9375 19.8333 17.7042V8.16667C19.8333 7.525 19.6051 6.97589 19.1485 6.51933C18.6919 6.06278 18.1424 5.83411 17.5 5.83333C16.8583 5.83333 16.3092 6.062 15.8527 6.51933C15.3961 6.97667 15.1674 7.52578 15.1667 8.16667V19.8333C15.1667 21.1167 14.7097 22.2153 13.7958 23.1292C12.8819 24.0431 11.7833 24.5 10.5 24.5Z" fill="black"/>
</svg>
        </div>
      </div>
      <div className="seatss">
        {getSeatsForSelectedRoute().length > 0 ? (
          getSeatsForSelectedRoute().map((deck, i) => (
           
            <Deck
              seatTaxFlyProView={seatTaxFlyProView}
              deck={deck}
              key={i}
              setSelectedSeat={(seat) =>
                setSelectedSeat((prevSelectedSeat) => {
                  const newSelectedSeat = [...prevSelectedSeat];
                  newSelectedSeat[selectedRouteIndex] = {
                    ...(newSelectedSeat[selectedRouteIndex] || {}),
                    [selectedTravelerIndex]: seat,
                  };
                  return newSelectedSeat;
                })
              }
              selectedSeat={
                selectedSeat[selectedRouteIndex]?.[selectedTravelerIndex] || ""
              }
              selectedSeatData={selectedSeatData[selectedRouteIndex]}
              setSelectedSeatData={(data) =>
                setSelectedSeatData((prevSelectedSeatData) => {
                  const newSelectedSeatData = [...prevSelectedSeatData];
                  newSelectedSeatData[selectedRouteIndex] = data;
                  return newSelectedSeatData;
                })
              }
            />
          ))
        ) : (
          <div className="m-5 d-flex justify-content-center align-items-center">Seat Select option is not available</div>
        )}
      </div>
    </div>
  );
};

export default SeatMap;
