import Layout from "components/Layout";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "react-bootstrap/Nav";
import TermsConditionComp from "components/TermsConditionComp";

const TermsCondition = () => {
  const [activeKey, setActiveKey] = useState("english");
  console.log(activeKey, "activeKey");
  const renderContent = () => {
    switch (activeKey) {
      case "english":
        console.log("english");
        break;
      case "swedish":
        console.log("swedish");
        break;
      default:
        console.log("default");
    }
  };
  return (
    <>
      <Helmet>
        <title>Terms & conditions</title>
      </Helmet>
      <Layout>
        <div className="container my-5 policyContent">
          <Nav
            variant="pills"
            activeKey={activeKey}
            onSelect={(selectedKey) => setActiveKey(selectedKey)}
            className="d-flex justify-content-center my-5"
          >
            <Nav.Item className="language_option">
              <Nav.Link eventKey="english">English</Nav.Link>
            </Nav.Item>
            <Nav.Item className="language_option">
              <Nav.Link eventKey="swedish">Swedish</Nav.Link>
            </Nav.Item>
          </Nav>
          <TermsConditionComp language={activeKey} />
        </div>
      </Layout>
    </>
  );
};

export default TermsCondition;
