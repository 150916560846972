import axios from "axios";
const createBackendServer = (baseURL) => {
  axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}api/`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 60 * 1000,
  });

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error.response.data;
      error.message = message || error.message;
      /*if(error?.response?.data?.errors)
            error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error);
    }
  );

  /*==========     AUTH Requests   ==========*/

  const authLogin = (body) => api.post("auth", body);
  const authSignUp = (body) => api.post("auth/register", body);
  const logout = () => api.get("/auth/logout");
  const authOtp = (body) => api.post("auth/verify", body);
  const updateProfile = (id, body) =>
    api.put(`user/update-profile/${id}`, body);

  const searchCode = (id) => api.get(`flight/code/?keyword=${id}`);
  const authPayment = (body) => api.post("flight/payment", body);
  const get_all_flight_bookings = (id) => api.get(`flight/booking/${id}`);
  const cancelFlight = (id) => api.post(`flight/cancelled/${id}`);
  const searchFlight = async (
    originCode,
    destinationCode,
    departureDate,
    adults,
    flightMode,
    travelClass,
    flightReturnMode,
    returnDate,
    travellerArray,
    currency,
    ip
  ) => {
    try {
      const response = await api.post(
        `flight/search/?originCode=${originCode}&destinationCode=${destinationCode}&departureDate=${departureDate}&adult=${adults}&flightMode=${flightMode}&travelClass=${travelClass}&flightReturnMode=${flightReturnMode}&returnDate=${returnDate}`,
        { body: travellerArray, currecny: currency, ip: ip }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const authResetPassword = (body) => api.put("auth/update-password", body);
  const authForget = (body) => api.post("auth/forget", body);
  const seatMapFlight = (body) => api.post("flight/seatMap", body);
  const branded_fares = ({ flight, ip }) =>
    api.post("flight/branded-fares", { flight: flight, ip: ip });
  const validateCoupon = (id, value) =>
    api.get(`coupon/validate/${id}?value=${value}`);
  const flightOrder = (body) => api.post("flight/create", body);
  const flightIssuance = (body) => api.post("flight/issue", body);
  const flightPriceConfirmBagsData = ({ flight, ip }) =>
    api.post("flight/confirm-price?include=bags", { flight, ip });
  const searchCityCode = (keyword) => api.get(`hotel/code?keyword=${keyword}`);
  const searchHotel = (keyword, adults, checkInDate, checkOutDate) =>
    api.get(
      `hotel/search?cityCode=${keyword}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&adults=${adults}`
    );
  const searchHotelOffers = (hotelIds, checkInDate, checkOutDate, adults) =>
    api.get(
      `hotel/searchOffers?checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&adults=${adults}&hotelIds=${hotelIds}`
    );
  const getFlightCommission = ({ id, type }) =>
    api.post(`flight/commission`, { type: type });
  const getSeatCommission = () => api.get(`flight/seat-commission`);
  const hotelOrder = (body) => api.post("hotel/create", body);
  const addCommunityMember = (body) => api.post("community", body);
  const sendContactusQuery = (body) => api.post("queries", body);
  const getBookingDetails = (id) => api.get(`flight/booking-details/${id}`);
  const createChecoutSession = (body) =>
    api.post("flight/create-checkout-session", body);

  return {
    createChecoutSession,
    getSeatCommission,
    getFlightCommission,
    hotelOrder,
    searchHotelOffers,
    searchCityCode,
    searchHotel,
    flightPriceConfirmBagsData,
    flightOrder,
    seatMapFlight,
    authPayment,
    authLogin,
    authSignUp,
    authOtp,
    logout,
    authForget,
    authResetPassword,
    updateProfile,
    searchCode,
    searchFlight,
    validateCoupon,
    get_all_flight_bookings,
    cancelFlight,
    addCommunityMember,
    sendContactusQuery,
    flightIssuance,
    branded_fares,
    getBookingDetails,
  };
};

const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
