import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const ThanksModal = ({ showModal, setShowModal }) => {
  return (
    <Modal
      show={showModal}
      size="xl"
      onHide={setShowModal}
    //   backdrop="static"
    //   keyboard={false}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h1 className="text-center fw-bolder mt-5 headings">
          🎉 Welcome to FlyProView Community! 🌍✨
        </h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            Thank you for joining our innovation journey! 🚀 We're thrilled to
            have you as a valued member of our community.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">📬 What's Next?</h1>
        <div className="col-lg-10 mx-auto ">
          <h5 className="text-secondary text-center fw-normal ">
            We've received your signup, and our team is excited to get in touch
            with you shortly. Expect an email from us that will guide you on how
            to make the most of your membership.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">
          🌟 Stay Connected:
        </h1>
        <div className="col-lg-10 mx-auto">
          <h5 className="text-secondary text-center fw-normal ">
            - <span className="fw-bold"> Follow Us on Instagram:</span>{" "}
            [@Flyproview_community] 📸 <br />
            Be the first to see behind-the-scenes, exclusive content, and
            updates on our journey.
          </h5>
          <h5 className="text-secondary text-center fw-normal ">
            - <span className="fw-bold"> Join the Conversation on Threads</span>{" "}
            [Flyproview_community] <br />
            Connect with fellow members, share your travel stories, and be a
            part of the lively discussions.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">
          🚀 Important Note:
        </h1>
        <div className="col-lg-10 mx-auto">
          <h5 className="text-secondary text-center fw-normal ">
            To ensure you don't miss out on any updates, please be aware that
            we'll exclusively communicate through Instagram and Threads. Make
            sure to follow us on both platforms.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">
          🔍 Did You Hear About Us from Instagram or Threads?
        </h1>
        <div className="col-lg-10 mx-auto">
          <h5 className="text-secondary text-center fw-normal ">
            If you discovered FlyProView  through our official Instagram
            or Threads account, make sure to follow our community pages as well.
            It's the best way to stay in the loop and enjoy all the exclusive
            content we have in store for you.
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">
          🚀 Get Ready for Something Amazing!
        </h1>
        <div className="col-lg-10 mx-auto">
          <h5 className="text-secondary text-center fw-normal ">
            As part of the FlyProView community, you're at the forefront of
            travel innovation. We can't wait to embark on this exciting
            adventure together!
          </h5>
        </div>
        <h1 className="text-center fw-bolder mt-5 headings">
          ✨ Thank you for choosing us to be your travel companion! ✨
        </h1>
        <div className="col-lg-10 mx-auto">
          <h5 className="text-secondary text-center fw-normal mt-5">
            Best regards,
            <br />
            The Flyproview Team
          </h5>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ThanksModal;
