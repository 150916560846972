import React from "react";
import SignUpComponent from "components/SignUpComponent";
import { Helmet } from "react-helmet";

const SignUp = () => {
  return(
     <>
     <Helmet>
        <title>SignUp</title>
      </Helmet>
     <SignUpComponent />
     </>
     );
};

export default SignUp;
