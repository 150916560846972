import React from "react";
import Navbars from "./Navbars";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const Location = useLocation();
  return (
    <>
      {/* {Location.pathname !== "/" &&
      Location.pathname !== "/flight_search" &&
      Location.pathname !== "/hotel_search" ? (
        ) : null} */}

      <Navbars />
      <main className="bodyView">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
