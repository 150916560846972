import React, { useState ,useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import apis from "services";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

const SignUpComponent = () => {
  const { user } = useSelector((store) => store.user);
 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    phone: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (user) {
      navigate("/flight_search");
    }
  }, []);



  const onChangeHandler = (e) => {
    let { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };
// signup function
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apis.authSignUp(formData);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/otp_verification",{state:{email:formData?.email,type:"verification"}});
        }, 2000);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e?.response?.data?.message,{id:1});
    }
  };

  return (
    <div class="container-fluid p-0 signup_bg d-flex align-items-center ">
      <div class="container rounded-4 contents">
        <form onSubmit={submitHandler}>
          <div class="row align-items-center my-3">
            <div class="col-md-6 col-lg-5 my-3">
              <h1 class="fw-bold text-white">Vacation feels like home</h1>
              <h6 class="fw-light text-white lh-base mt-3">
                The most comfortable accommodation you can find in our website,
                spread all over the world
              </h6>
            </div>
            <div class="ms-lg-auto col-md-6 col-lg-5 my-3 my-3">
              <div class="row bg-white p-3 py-4 rounded-3 ">
                <h1 class="fw-bold text-center">SignUp</h1>
                <h6 class="fw-normal text-secondary text-center">
                  Let's signup for your vacation with loved ones
                </h6>
                <div class="row p-0 m-0 mt-3  w-100">
                  <div class="col-6 p-0 pe-1">
                    <p class="m-0 text-secondary">First Name</p>
                    <input
                      type="text"
                      id="firstName"
                      onChange={onChangeHandler}
                      required
                      class="px-1 w-100  hotel_form_input text-secondary px-2"
                    />
                  </div>
                  <div class="col-6 p-0 ps-1">
                    <p class="m-0 text-secondary">Last Name</p>
                    <input
                      type="text"
                      id="lastName"
                      onChange={onChangeHandler}
                      required
                      class="px-1 w-100 hotel_form_input text-secondary px-2"
                    />
                  </div>
                </div>
                <div class="row p-0 m-0 mt-3  w-100">
                  <div class="col-6 p-0 pe-1">
                    <p class="m-0 text-secondary">Age</p>
                    <input
                      type="number"
                      id="age"
                      onChange={onChangeHandler}
                      required
                      class="px-1 w-100  hotel_form_input text-secondary"
                    />
                  </div>
                  <div class="col-6 p-0 ps-1">
                    <p class="m-0 text-secondary">Phone</p>
                    <input
                      type="text"
                      id="phone"
                      onChange={onChangeHandler}
                      required
                      class="px-1 w-100 hotel_form_input text-secondary"
                    />
                  </div>
                </div>
                <div class="col-12 mt-3 p-0">
                  <p class="m-0 text-secondary">Email</p>
                  <input
                    type="email"
                    id="email"
                    onChange={onChangeHandler}
                    required
                    class="px-2 hotel_form_input px-2"
                    placeholder="user@gmail.com"
                  />
                </div>

                <div class="col-12 mt-3 p-0">
                  <p class="m-0 text-secondary">Password</p>
                  <input
                    type="password"
                    id="password"
                    onChange={onChangeHandler}
                    required
                    minLength={8}
                    class="px-2 hotel_form_input px-2"
                    placeholder="********"
                  />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  class="btn btn-purple w-100 mt-3 py-2 d-flex justify-content-center align-items-center"
                >
                  {loading ?<> 
                  
                  {/* <Spinner animation="border" variant="light" size="sm" /> SignUp */}
                  <ThreeDots
  visible={true}
  height="25"
  width="70"
  color="#fff"
  radius="5"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
                  </> 
                    
                    : "SignUp"}
                </button>
                <h6 class="fw-light text-center mt-3">
                  Have Already an Account?
                  <Link to="/login">
                    <span class="text-purple fw-semibold"> Login</span>
                  </Link>
                </h6>

                <p class="text-center mt-3">or SignUp with</p>
                <div class="d-flex justify-content-center">
                  <FcGoogle className="fs-2 me-3" />
                  <FaApple className="fs-2 ms-3" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpComponent;
