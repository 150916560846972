import UserProfile from 'components/UserProfile'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout";

const Profile = () => {
  return (
     <Layout>
     <Helmet>
        <title>Profile</title>
      </Helmet>
    <UserProfile />
     </Layout>
  )
}

export default Profile