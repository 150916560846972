import React, { useState } from "react";
// import Spinner from "react-bootstrap/Spinner";
// import { IoIosWarning } from "react-icons/io";
// import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import {
  // CardElement,
  useStripe,
  // CardNumberElement,
  // CardExpiryElement,
  // CardCvcElement,
  // useElements,
} from "@stripe/react-stripe-js";
import apis from "services";
import SuccessModal from "./Modal/SuccessModal";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

export default function CheckoutForm({
  coupon,
  handlePaymentModal,
  item,
  total,
  flight_facilities,
  formattedGuests,
  currency,
  ip,
}) {
  // const navigate = useNavigate();
  // const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  // const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  // const elements = useElements();
  // const cardStyle = {
  //   style: {
  //     base: {
  //       color: "#32325d",
  //       fontFamily: "Arial, sans-serif",
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       "::placeholder": {
  //         color: "#32325d",
  //       },
  //     },
  //     invalid: {
  //       fontFamily: "Arial, sans-serif",
  //       color: "#fa755a",
  //       iconColor: "#fa755a",
  //     },
  //   },
  // };

  const { user } = useSelector((store) => store.user);

  const newFormated = () => {
    formattedGuests?.map((res) => {
      res.dateOfBirth = moment(res.dateOfBirth).format("yyyy-MM-DD");
      res.documents[0].expiryDate = moment(res.documents.expiryDate).format(
        "yyyy-MM-DD"
      );
      res.documents[0].issuanceDate = moment(res.dateOfBirth).format(
        "yyyy-MM-DD"
      );
    });
  };

  newFormated();

  // const [formData, setFormData] = useState({
  //   user: user,
  //   flight: item,
  //   payment: "",
  //   bookId: "",
  //   travelers: formattedGuests,
  //   flight_facilities: flight_facilities,
  // });
  // const handleChange = async (event) => {
  //   setDisabled(event.empty);
  //   setError(event.error ? event.error.message : "");
  // };

  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const response = await apis.authPayment({
      ...item,
      user: user,
      flight: item,
      grandTotalWithAirProService: total,
      couponId: coupon?._id,
      travelers: formattedGuests,
      flight_facilities: flight_facilities,
      ip: ip,
      currency: currency,
    });
    console.log("responseresponse", response);
    if (response?.data?.sessionId) {
      // const payload = await stripe.confirmCardPayment(
      //   response?.data?.clientSecret,
      //   {
      //     payment_method: {
      //       card: element,
      //     },
      //   }
      // );

      const { error } = await stripe.redirectToCheckout({
        sessionId: response?.data?.sessionId,
      });

      if (error) {
        setProcessing(false);
        console.error("Error redirecting to checkout:", error);
      }

      // setFormData((prevState) => ({
      //   ...prevState,
      //   bookId: response?.data?.bookId,
      //   payment: payload,
      // }));

      // if (payload.error) {
      //   setError(`${payload.error.message}`);
      //   setProcessing(false);
      // } else {
      //   setError(null);
      //   try {
      //     setFormData((prevState) => ({
      //       ...prevState,
      //       bookId: response?.data?.bookId,
      //       payment: payload,
      //     }));

      //     try {
      //       // creating flight order
      //       const data = {
      //         bookId: response?.data?.bookId,
      //         flight: item,
      //         payment: payload,
      //         user: formData?.user,
      //         travelers: formattedGuests,
      //         flight_facilities: flight_facilities,
      //       };
      //       const res = await apis.flightOrder(data);
      //       toast.success(res?.data?.message);
      //       try {
      //         // issuing the ticket
      //         let issueTickets = await apis.flightIssuance({
      //           data: res?.data,
      //           others: data,
      //         });
      //         toast.success(issueTickets?.data?.message);
      //         sessionStorage.clear();
      //         setSucceeded(true);
      //         setTicektData(issueTickets?.data.ticket)
      //         handlePaymentModal();
      //         setShowTicketModal(true)
      //         // navigate("/");
      //       } catch (error) {
      //         if (error?.message?.[0]) {
      //           toast.error(error?.message?.[0]?.detail);
      //         }
      //         console.log(error);
      //       }
      //     } catch (error) {
      //       if (error?.message?.[0]) {
      //         toast.error(error?.message?.[0]?.detail);
      //       }
      //       console.log(error);
      //     }

      //     setProcessing(false);
      //   } catch (e) {
      //     setProcessing(false);
      //     console.log(e, "creating_error");
      //   }
      // }
    }
    setProcessing(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="pay_form">
        <button
          disabled={processing}
          id="submit"
          className="btn gradient_btn0  w-100 flight_input bg-white text-gold1 d-flex justify-content-center align-items-center"
        >
          <span id="button-text">
            {processing ? (
              <>
                {/* <Spinner animation="border" size="sm" /> Processing... */}
                <ThreeDots
                  visible={true}
                  height="25"
                  width="70"
                  color="#000"
                  radius="5"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </>
            ) : (
              `Pay Now ${total} SEK`
            )}
          </span>
        </button>

        {error && (
          <div className="card-error text-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </form>

      <SuccessModal
        handleToggleModal={handleToggleModal}
        showModal={showModal}
      />
    </>
  );
}
