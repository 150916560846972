import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { MdFlightTakeoff } from "react-icons/md";
import { BsPersonWorkspace } from "react-icons/bs";
import { TbReservedLine } from "react-icons/tb";
import { MdContactMail } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaPeopleCarry } from "react-icons/fa";
import { useLocation, Link, useNavigate } from "react-router-dom";
import logo from "./assets/fly_pro_view.png";
import useDummy from "./assets/userDummy.avif";
import { useDispatch, useSelector } from "react-redux";
import apis from "services";
import { logout } from "redux/slices/userSlice";

const Navbars = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { user } = useSelector((store) => store.user);
  const navigate = useNavigate();
  // logout funcitonally
  const handleLogout = async () => {
    try {
      await apis.logout();
      sessionStorage.clear()
      dispatch(logout());
      if(pathname?.includes("flight_details")){
        navigate("/");
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-dark header"
        id="hide-on-mobile"
      >
        <div className="container-fluid px-4">
          <Link to="/">
            <span className="navbar-brand d-lg-none d-block mx-auto">
              <img src={logo} className="img-fluid" alt="" />
            </span>
          </Link>
          <button
            className="navbar-toggler d-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          <div
            className="collapse navbar-collapse d-flex"
            id="navbarTogglerDemo01"
          >
            <Link to="/">
              <span className="navbar-brand d-lg-block d-none mx-auto">
                <img src={logo} className="img-fluid" alt="" />
              </span>
            </Link>
            <ul className={pathname === "/" ? "navbar-nav" : "navbar-nav"}>
              <li className="nav-item">
                <span
                  className={`navItems ${
                    pathname.includes("flight")
                      ? "text-warning fw-semibold"
                      : "text__lights"
                  }`}
                >
                  <Link className="active" to="/flight_search">
                    Flight
                  </Link>
                </span>
              </li>
              {/* <li className="pe-0">
                <span
                  className={` navItems ${
                    pathname.includes("hotel")
                      ? "text-dark fw-semibold"
                      : "text__lights"
                  }`}
                >
                  <Link to="/hotel_search">Hotels</Link>
                </span>
              </li> */}
              {user && (
                <li className="">
                  <span className=" navItems text__lights">
                    <Link to="/bookings">Bookings</Link>
                  </span>
                </li>
              )}
              {user && (
              <li className="">
                <span
                  className={` navItems ${
                    pathname.includes("community")
                      ? "text-warning fw-semibold"
                      : "text__lights"
                  }`}
                >
                  <Link to="/community">Community</Link>
                </span>
              </li>)}
              <li className="nav-item">
                <span
                  className={` navItems ${
                    pathname.includes("about-us")
                      ? "text-warning fw-semibold"
                      : "text__lights"
                  }`}
                >
                  <Link to="/about-us"> About Us</Link>
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={` navItems ${
                    pathname.includes("contact-us")
                      ? "text-warning fw-semibold"
                      : "text__lights"
                  }`}
                >
                  <Link to="/contact-us">Contact Us</Link>
                </span>
              </li>
              
              {/* <li className="nav-item">
                <span className=" navItems text__lights">
                  About Us
                </span>
              </li> */}
            </ul>
          </div>
          <div
            className={
              pathname === "/" && !user
                ? "user_profile "
                : "user_profile rounded-5 "
            }
          >
            {!user ? (
              <>
                <Link to={"/login"}>
                  <button className="login">Sign in</button>
                </Link>
                <Link to={"/signup"}>
                  <button className="signup">Sign up</button>
                </Link>
              </>
            ) : (
              <>
                <Dropdown>
                  <Dropdown.Toggle className="userDropDown gradient-border d-flex align-items-center p-0 py-1 px-2 shadow-sm">
                    <img
                      src={user?.image ? user?.image : useDummy}
                      className="userProfileImg"
                      alt=""
                    />
                    <p className="m-0 m-2">Welcome {user?.firstName}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="overflow-hidden">
                  <Dropdown.Item
                    onClick={() => {
                      navigate("/profile");
                      setShow(false);
                    }}
                  >Profile
                      {/* <Link to="/profile">Profile</Link> */}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link onClick={handleLogout}>Logout</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </nav>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-dark header"
        id="hide-on-desktop"
      >
        <div className="mobile-header">
          <div className="left" onClick={() => setShow(true)}>
            <svg
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0.333313H24V2.99998H0V0.333313ZM0 9.66665H16V12.3333H0V9.66665ZM0 19H24V21.6666H0V19Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="center">
            <Link to="/">
              <img src={logo} className="img-fluid" alt="" />
            </Link>
          </div>
          <div className="right"></div>
        </div>
      </nav>
      <div className={`mobile-menu ${show ? "show" : ""}`}>
        <div className="mobile-header">
          <div className="left" onClick={() => setShow(false)}>
            {/* <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0.333313H24V2.99998H0V0.333313ZM0 9.66665H16V12.3333H0V9.66665ZM0 19H24V21.6666H0V19Z" fill="white" />
            </svg> */}
            <IoClose className="text-white fs-1" />
          </div>
          <div className="center">
            <Link to="/">
              <img src={logo} className="img-fluid" alt="" />
            </Link>
          </div>
          <div className="right"></div>
        </div>
        <div
          className={
            pathname === "/" && !user
              ? "user_profile "
              : "user_profile rounded-5 "
          }
        >
          {user && (
            <>
              <Dropdown>
                <Dropdown.Toggle className="userDropDown gradient-border d-flex align-items-center p-0 py-1 px-2 shadow-sm">
                  <img
                    src={user?.image ? user?.image : useDummy}
                    className="userProfileImg"
                    alt=""
                  />
                  <p className="m-0 m-2">Welcome {user?.firstName}</p>
                </Dropdown.Toggle>

                <Dropdown.Menu className="overflow-hidden">
                  <Dropdown.Item
                    onClick={() => {
                      navigate("/profile");
                      setShow(false);
                    }}
                  >
                    Profiles
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link
                      onClick={() => {
                        handleLogout();
                        setShow(false);
                      }}
                    >
                      Logout
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        <div className="links">
          <ul onClick={() => setShow(false)}>
            <li>
              <span
                className={` navItems ${
                  pathname.includes("flight")
                    ? "text-warning fw-semibold"
                    : "text__lights"
                }`}
              >
                <Link className="active" to="/flight_search">
                  Flight
                </Link>
              </span>
            </li>
            {user && (
              <li className="">
                <span className=" navItems text__lights">
                  <Link to="/bookings">Bookings</Link>
                </span>
              </li>
            )}
                 {user && (
            <li className="">
              <span
                className={` navItems ${
                  pathname.includes("community")
                    ? "text-warning fw-semibold"
                    : "text__lights"
                }`}
              >
                <Link to="/community">Community</Link>
              </span>
            </li> )}
            <li className="nav-item">
              <span
                className={` navItems ${
                  pathname.includes("about-us")
                    ? "text-warning fw-semibold"
                    : "text__lights"
                }`}
              >
                <Link to="/about-us">About Us</Link>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={` navItems ${
                  pathname.includes("contact-us")
                    ? "text-warning fw-semibold"
                    : "text__lights"
                }`}
              >
                <Link to="/contact-us">Contact Us</Link>
              </span>
            </li>
            {/* <li className="nav-item">
                <span className=" navItems text__lights">
                  Work for Us
                </span>
              </li> */}
          </ul>
          <div
            className={
              pathname === "/" && !user
                ? "user_profile "
                : "user_profile rounded-5 "
            }
          >
            {!user && (
              <>
                <Link to={"/login"} onClick={() => setShow(false)}>
                  <button className="login">Sign in</button>
                </Link>
                <Link to={"/signup"} onClick={() => setShow(false)}>
                  <button className="signup">Sign up</button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbars;
