import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import apis from "services";
import { toast } from "react-hot-toast";
import { setUser } from "redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

const LoginComponent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    let { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  useEffect(() => {
    if (user) {
      navigate("/flight_search");
    }
  }, []);

  // login funcionally
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apis.authLogin(formData);
      if (response?.data?.status) {
        if (
          response?.data?.message ===
          "We have sent you an OTP via email for verification.!"
        ) {
          toast.success(response?.data?.message);
          setTimeout(() => {
            navigate("/otp_verification", {
              state: { email: formData?.email, type: "verification" },
            });
          }, 2000);
        } else {
          toast.success(response?.data?.message);
          dispatch(setUser(response?.data?.user));

          setTimeout(() => {
            navigate("/flight_search");
          }, 1000);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e?.response?.data?.message);
    }
  };
  return (
    <div className="container-fluid p-0 bg-plane d-flex align-items-center ">
      <div className="container rounded-4 contents">
        <div className="row align-items-center my-3">
          <div className="col-md-6 col-lg-5">
            <h1 className="fw-bold text-white">Vacation feels like home</h1>
            <h6 className="fw-light text-white lh-base mt-3">
              The most comfortable accommodation you can find in our website,
              spread all over the world
            </h6>
          </div>
          <div className="ms-lg-auto col-md-6 col-lg-4 my-3">
            <form onSubmit={submitHandler}>
              <div className="row bg-white p-3 py-4 rounded-3 ">
                <h1 className="fw-bold text-center">Login</h1>
                <h6 className="fw-normal text-secondary text-center">
                  Let's Login for your vacation with loved ones
                </h6>
                <div className="col-12 mt-3 p-0">
                  <p className="m-0 text-secondary">Email</p>
                  <input
                    type="email"
                    className="hotel_form_input px-2"
                    placeholder="Email"
                    id="email"
                    required
                    onChange={onChangeHandler}
                  />
                </div>

                <div className="col-12 mt-3 p-0">
                  <p className="m-0 text-secondary">Password</p>
                  <input
                    type="password"
                    className="hotel_form_input px-2"
                    placeholder="********"
                    id="password"
                    minLength={8}
                    required
                    onChange={onChangeHandler}
                  />
                </div>

                <Link
                  to={"/forgot_password"}
                  className="text-end text-danger m-0 mt-3"
                >
                  Forgot Password ?
                </Link>

                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-purple w-100 mt-3 py-2 d-flex align-items-center justify-content-center"
                >
                  {loading ? (
                    <>
                      {/* <Spinner animation="border" variant="light" size="sm" /> Login */}
                      <ThreeDots
                        visible={true}
                        height="25"
                        width="70"
                        color="#fff"
                        radius="5"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </>
                  ) : (
                    "Login"
                  )}
                </button>

                <h6 className="fw-light text-center mt-3">
                  Have no Account?{" "}
                  <Link to="/signup">
                    <span className="text-purple fw-semibold">Signup Now</span>
                  </Link>
                </h6>

                <p className="text-center mt-3">or login with</p>
                <div className="d-flex justify-content-center">
                  <FcGoogle className="fs-2 me-3" />
                  <FaApple className="fs-2 ms-3" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
