import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
// import Slider from "@mui/material/Slider";
import Slider from "@mui/material/Slider";
import ReactPaginate from "react-paginate";
import { MdCheckBoxOutlineBlank,MdCheckBox } from "react-icons/md";

const FlightFilters = ({
  priceRange,
  setPriceRange,
  priceMinimumANdMaximum,
  setSelectedStops,
  selectedStops,
  addClass,
  setAddClass,
  filterFlights,
}) => {
  const handleSliderChange = (event, newValue) => {
    setPriceRange(newValue);
  };

 

  return (
    <>
      <div className="result_section-left-wrap-one">
        <h3 className="text-center" onClick={() => setAddClass(!addClass)}>
          Filters
        </h3>
        <button
          onClick={() => {
            setSelectedStops("All");
            setPriceRange([
              priceMinimumANdMaximum?.minPrice,
              priceMinimumANdMaximum?.maxPrice,
            ]);

            filterFlights(
              [
                priceMinimumANdMaximum?.minPrice,
                priceMinimumANdMaximum?.maxPrice,
              ],
              true
            );
          }}
        >
          Reset
        </button>
      </div>
      <hr className={` ${addClass ? "hide-filter-mobile" : ""}`} />
      <div
        className={`result_section-left-wrap-two ${
          addClass ? "hide-filter-mobile" : ""
        }`}
      >
        <h4>Flight</h4>
        <div className="options">
          <ul>
            <li>
              {selectedStops === "All" ? (
                // <input
                //   class="check"
                //   type="checkbox"
                //   value=""
                //   id="connectedFlight"
                //   checked={selectedStops === "All"}
                //   onChange={(e) => setSelectedStops("All")}
                // />
                <MdCheckBox className="fs-3 text-gold" onClick={(e) => setSelectedStops("All")} />
              ) : (
                <>
                  {/* <div
                    className="check"
                    onClick={() => setSelectedStops("All")}
                  ></div> */}
                  <MdCheckBoxOutlineBlank className="fs-3 text-gold" onClick={() => setSelectedStops("All")} />

                </>
              )}
              {/* <input
                class="check"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "All"}
                onChange={(e) =>
                  setSelectedStops("All")
                }
              /> */}
              <p>All Flight</p>
            </li>
            <li>
              {/* <div className="check"></div> */}

              {selectedStops === "Connected" ? (
                // <input
                //   class="check"
                //   type="checkbox"
                //   value=""
                //   id="connectedFlight"
                //   checked={selectedStops === "Connected"}
                //   onChange={(e) => setSelectedStops("Connected")}
                // />
                <MdCheckBox className="fs-3 text-gold" onClick={(e) => setSelectedStops("Connected")} />
              ) : (
                <>
                  {/* <div
                    className="check"
                    onClick={() => setSelectedStops("Connected")}
                  ></div> */}
                  <MdCheckBoxOutlineBlank className="fs-3 text-gold" onClick={() => setSelectedStops("Connected")} />
                </>
              )}

              {/* <input
                class="check"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "Connected"}
                onChange={(e) =>
                  setSelectedStops("Connected")
                }
              /> */}
              <p>Connected Flight</p>
            </li>
            <li>
              {/* <div className="check"></div> */}

              {selectedStops === "Directed" ? (
                // <input
                //   class=" filtercheckbox"
                //   type="checkbox"
                //   value=""
                //   id="connectedFlight"
                //   checked={selectedStops === "Directed"}
                //   onChange={(e) => setSelectedStops("Directed")}
                 
                // />
                <MdCheckBox className="fs-3 text-gold" onClick={(e) => setSelectedStops("Directed")} />

              ) : (
                <>
                  {/* <div
                    className="check"
                    onClick={() => setSelectedStops("Directed")}
                  ></div> */}
                  <MdCheckBoxOutlineBlank className="fs-3 text-gold" onClick={() => setSelectedStops("Directed")} />

                </>
              )}
              {/* <input
                class="check"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "Directed"}
                onChange={(e) =>
                  setSelectedStops("Directed")
                }
              /> */}
              <p>Direct Flight</p>
            </li>
          </ul>
        </div>
      </div>
      {/* <hr className={` ${addClass ? 'hide-filter-mobile' : ''}`}/> */}
      {/* <div  className={`result_section-left-wrap-two ${addClass ? 'hide-filter-mobile' : ''}`}>
        <h4>Category</h4>
        <div className="options">
          <ul>
            <li>
              <div className="check"></div>
              <p>Promo Deals</p>
            </li>
            <li>
              <div className="check"></div>
              <p>One Day Trip</p>
            </li>
            <li>
              <div className="check"></div>
              <p>Top Vacation</p>
            </li>
            <li>
              <div className="check"></div>
              <p>Things To do</p>
            </li>
          </ul>
        </div>
      </div> */}
      <hr className={` ${addClass ? "hide-filter-mobile" : ""}`} />
      <div
        className={`result_section-left-wrap-three ${
          addClass ? "hide-filter-mobile" : ""
        }`}
      >
        <h4>Price Range</h4>
        <div className="filter-input-wrap">
          {/* <Slider
              value={priceRange}
              onChange={handleSliderChange}
              min={priceMinimumANdMaximum?.minPrice}
              max={priceMinimumANdMaximum?.maxPrice}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `€${value}`}
            /> */}
          <Slider
            value={priceRange}
            onChange={handleSliderChange}
            min={priceMinimumANdMaximum?.minPrice}
            max={priceMinimumANdMaximum?.maxPrice}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `€${value}`}
            sx={{
              color: "#C3A465",
            }}
          />
          <Form.Label className="d-flex justify-content-between">
            <span>SEK {priceRange[0]}</span> <span>SEK {priceRange[1]}</span>
          </Form.Label>

          {/* <div className="filter-input">
            <div className="filter-input-left">$</div>
            <div className="filter-input-right"><input type="number" placeholder="Minimum Price" /></div>
          
          </div>
          <div className="filter-input">
            <div className="filter-input-left">$</div>
            <div className="filter-input-right"><input type="number" placeholder="Maximum Price" /></div>
          </div> */}
        </div>
      </div>
      {/* <hr className={` ${addClass ? 'hide-filter-mobile' : ''}`}/> */}
      {/* <div  className={`result_section-left-wrap-two ${addClass ? 'hide-filter-mobile' : ''}`}>
        <h4>Rating</h4>
        <div className="options">
          <ul>
            <li>
              <div className="check"></div>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99996 11.0667L10.28 13.4533C10.8 13.84 11.5066 13.32 11.3066 12.7067L10.0533 8.85335L13.2933 6.54668C13.8266 6.17335 13.56 5.33335 12.9066 5.33335H8.93329L7.63996 1.30668C7.43996 0.693346 6.57329 0.693346 6.37329 1.30668L5.06662 5.33335H1.09329C0.439956 5.33335 0.173289 6.17335 0.706623 6.54668L3.94662 8.85335L2.69329 12.7067C2.49329 13.32 3.19996 13.8267 3.71996 13.4533L6.99996 11.0667Z" fill="#F9BC1D" />
              </svg>
              <p>5 Star</p>
            </li>
            <li>
              <div className="check"></div>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99996 11.0667L10.28 13.4533C10.8 13.84 11.5066 13.32 11.3066 12.7067L10.0533 8.85335L13.2933 6.54668C13.8266 6.17335 13.56 5.33335 12.9066 5.33335H8.93329L7.63996 1.30668C7.43996 0.693346 6.57329 0.693346 6.37329 1.30668L5.06662 5.33335H1.09329C0.439956 5.33335 0.173289 6.17335 0.706623 6.54668L3.94662 8.85335L2.69329 12.7067C2.49329 13.32 3.19996 13.8267 3.71996 13.4533L6.99996 11.0667Z" fill="#F9BC1D" />
              </svg>
              <p>4 Star</p>
            </li>
            <li>
              <div className="check"></div>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99996 11.0667L10.28 13.4533C10.8 13.84 11.5066 13.32 11.3066 12.7067L10.0533 8.85335L13.2933 6.54668C13.8266 6.17335 13.56 5.33335 12.9066 5.33335H8.93329L7.63996 1.30668C7.43996 0.693346 6.57329 0.693346 6.37329 1.30668L5.06662 5.33335H1.09329C0.439956 5.33335 0.173289 6.17335 0.706623 6.54668L3.94662 8.85335L2.69329 12.7067C2.49329 13.32 3.19996 13.8267 3.71996 13.4533L6.99996 11.0667Z" fill="#F9BC1D" />
              </svg>
              <p>3 Star</p>
            </li>
            <li>
              <div className="check"></div>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99996 11.0667L10.28 13.4533C10.8 13.84 11.5066 13.32 11.3066 12.7067L10.0533 8.85335L13.2933 6.54668C13.8266 6.17335 13.56 5.33335 12.9066 5.33335H8.93329L7.63996 1.30668C7.43996 0.693346 6.57329 0.693346 6.37329 1.30668L5.06662 5.33335H1.09329C0.439956 5.33335 0.173289 6.17335 0.706623 6.54668L3.94662 8.85335L2.69329 12.7067C2.49329 13.32 3.19996 13.8267 3.71996 13.4533L6.99996 11.0667Z" fill="#F9BC1D" />
              </svg>
              <p>2 Star</p>
            </li>
            <li>
              <div className="check"></div>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99996 11.0667L10.28 13.4533C10.8 13.84 11.5066 13.32 11.3066 12.7067L10.0533 8.85335L13.2933 6.54668C13.8266 6.17335 13.56 5.33335 12.9066 5.33335H8.93329L7.63996 1.30668C7.43996 0.693346 6.57329 0.693346 6.37329 1.30668L5.06662 5.33335H1.09329C0.439956 5.33335 0.173289 6.17335 0.706623 6.54668L3.94662 8.85335L2.69329 12.7067C2.49329 13.32 3.19996 13.8267 3.71996 13.4533L6.99996 11.0667Z" fill="#F9BC1D" />
              </svg>
              <p>1 Star</p>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-12 bg-white my-2 rounded-3 p-3">
            <Form.Label className="d-flex justify-content-between">
              Price
            </Form.Label>
            <Slider
              value={priceRange}
              onChange={handleSliderChange}
              min={priceMinimumANdMaximum?.minPrice}
              max={priceMinimumANdMaximum?.maxPrice}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `€${value}`}
            />
            <Form.Label className="d-flex justify-content-between">
              <span>€{priceRange[0]}</span> <span>€{priceRange[1]}</span>
            </Form.Label>
          </div>


          <div className="col-sm-6 col-lg-12 bg-white my-2 rounded-3 p-3">

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "All"}
                onChange={(e) =>
                  setSelectedStops("All")
                }
              />
              <label class="form-check-label" for="connectedFlight">
                All Flight
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "Connected"}
                onChange={(e) =>
                  setSelectedStops("Connected")
                }
              />
              <label class="form-check-label" for="connectedFlight">
                Connected Flight
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="connectedFlight"
                checked={selectedStops === "Directed"}
                onChange={(e) =>
                  setSelectedStops("Directed")
                }
              />
              <label class="form-check-label" for="connectedFlight">
                Direct Flight
              </label>
            </div>
          </div>

        </div>
      </div> */}
    </>
  );
};

export default FlightFilters;
